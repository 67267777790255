import { PageContainer } from "../../PageContainer";
import {
    Text,
    Box,
    UnorderedList,
    ListItem,
    Alert,
    AlertIcon,
    Code,
    TableContainer,
    Table,
    Thead,
    Th,
    Tr,
    Tbody,
    Td,
} from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT, SECONDARY_PRODUCT } from "../../../config/BRAND_NAME";

export const LighthouseDocumentation = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Third Party Tools"}
                menuItem1Link={ROUTES.DOCUMENTATION_THIRD_PARTY_DEFAULTS}
                menuItem2={`Lighthouse `}
            />
            <Header pb={"spacer-8"}>Lighthouse</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Lighthouse is an open-source resource from Google Chrome that allows developers to audit the
                performance, accessibility, best practices, and SEO of any page, public or private/authenticated.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                All Lighthouse tests measure lab data, which is primarily meant for developers. Google's
                documentation&nbsp;
                <Link
                    textDecoration="underline"
                    to="https://web.dev/articles/lab-and-field-data-differences"
                    target="new"
                >
                    states
                </Link>{" "}
                that lab tests are run with a high level of control from end to end and reflect a "single device
                connected to a single network run from a single geographic location."
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As such, lab data—and, by extension, Lighthouse—is best used to test changes, debug issues, and
                determine good practices.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Overview
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Lighthouse can be{" "}
                <Link
                    textDecoration="underline"
                    to="https://developer.chrome.com/docs/lighthouse/overview"
                    target="new"
                >
                    accessed in several ways
                </Link>
                , but the easiest options for non-developers are Lighthouse in Chrome DevTools—explained in the
                following paragraph—or Lighthouse via{" "}
                <Link textDecoration="underline" to="https://pagespeed.web.dev/" target="new">
                    PageSpeed Insights
                </Link>
                . Other options include running Lighthouse from the command line, as a Node module, or as a{" "}
                <Link
                    textDecoration="underline"
                    to="https://chromewebstore.google.com/detail/lighthouse/blipmdconlkpinefehnmjammfjpmpbjk?pli=1"
                    target="new"
                >
                    Chrome extension
                </Link>
                .
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Anyone can run Lighthouse reports on any homepage, collection page, product page, etc., on a Chrome
                browser by right-clicking on the page and selecting <Code>Inspect</Code>. Alternatively, you can press{" "}
                <Code>fn</Code> + <Code>F12</Code> or <Code>F12</Code> by itself, depending on your keyboard, to open
                Chrome DevTools.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                From there, navigate to the <Code>Lighthouse</Code> tab. Lighthouse conducts several audits, including
                "Performance" and "Accessibility" audits, which you can include as needed by selecting the appropriate
                categories. You can also choose between mobile or desktop for your report. Click "Analyze page load"
                when the settings are configured for your page. Your report will begin loading.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-24/8487e776-e5d2-4ee2-ae3c-82fa4b178b63/screenshot.jpeg?tl_px=0,0&br_px=1288,315&force_format=png&width=1120.0"
                alt="Lighthouse tab of Chrome DevTools"
                loading="eager"
            />
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Benefits Of Lighthouse:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    The lab testing tool assists with the diagnosis of performance issues, especially on slower networks
                    and connections.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    Unlike field data tools such as the{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_THIRD_PARTY_CRUX} target="new">
                        Chrome User Experience Report
                    </Link>
                    , Lighthouse can get data from any page—public or private.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    Lighthouse delivers immediate feedback and insights for a site's developers.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Limitations Of Lighthouse:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    The results are a snapshot of data that may or may not be reflective of the general user experience.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    Lab data often struggles to test personalized content such as elements related to cookies and
                    advertisements.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    Lighthouse has{" "}
                    <Link textDecoration="underline" to="https://web.dev/articles/lighthouse-user-flows" target="new">
                        traditionally struggled to capture
                    </Link>
                    &nbsp;user interactions and pages loaded with a warm cache, which refers to pages loaded after the
                    initial page load. Visit the{" "}
                    <a href="#nostra" style={{ textDecoration: "underline" }}>
                        {BRAND_NAME} + Lighthouse section
                    </a>{" "}
                    to see how {BRAND_NAME} has reconfigured Lighthouse's default behavior to account for caching.
                </ListItem>
            </UnorderedList>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    This guide will focus on the "Performance" audit, as it measures key metrics based on factors such
                    as rendering, JavaScript execution, stylesheet management, and redundancy. For information on the
                    other audit categories, visit the{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/lighthouse/overview"
                        target="new"
                    >
                        Google Lighthouse documentation
                    </Link>
                    .
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Performance Score
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Each Lighthouse "Performance" audit will return an overall performance score along with the related
                metrics, a diagnostics section, and the total number of passed audits.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    If you see a warning saying, "There were issues affecting this run of Lighthouse…" in your report,
                    rerun the report from an incognito window.
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-24/e4a6e753-f6b4-4e08-8c7c-df541b3129f6/screenshot.jpeg?tl_px=0,0&br_px=970,757&force_format=png&width=1120.0"
                alt="Google's Lighthouse performance score and metrics"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The returned performance score is a weighted average of several key metrics. Those individual metric
                scores are calculated behind the scenes with varying levels of significance, thus affecting the weighted
                average differently.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The metrics are weighted as follows, according to{" "}
                <Link textDecoration="underline" to="https://googlechrome.github.io/lighthouse/scorecalc/" target="new">
                    Chrome's Lighthouse Scoring Calculator:
                </Link>
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Total Blocking Time (TBT): 30%</ListItem>
                <ListItem pb={"spacer-4"}>Largest Contentful Paint (LCP): 25%</ListItem>
                <ListItem pb={"spacer-4"}>Cumulative Layout Shift (CLS): 25%</ListItem>
                <ListItem pb={"spacer-4"}>First Contentful Paint (FCP): 10%</ListItem>
                <ListItem pb={"spacer-6"}>Speed Index (SI): 10%</ListItem>
            </UnorderedList>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-25/3b7c8e15-bd45-497d-ac56-96327a68a5aa/screenshot.jpeg?tl_px=0,0&br_px=2670,1454&force_format=png&width=1120.0"
                alt="Chrome's Lighthouse Scoring Calculator"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Each metric is given a score of 0-100, based on where the tested page falls in a scoring distribution
                created from real website performance data gathered by the{" "}
                <Link textDecoration="underline" to="https://httparchive.org/" target="new">
                    HTTP Archive
                </Link>
                . That means your LCP, CLS, etc. are calculated in a comparative manner rather than as individual drops
                in a bucket.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Because the scores are calculated on a distribution curve,{" "}
                    <strong>
                        the closer your metric score is to 100, the more improvements it will take to make additional
                        score gains.
                    </strong>{" "}
                    Learn more about the scoring practices in the official{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/lighthouse/performance/performance-scoring"
                        target="new"
                    >
                        Lighthouse documentation
                    </Link>
                    .
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you are curious about your individual metric scores or want to see how changing one metric would
                affect your performance, click "See calculator" below your overall score at the top of your Lighthouse
                report. You can also hover your cursor over your total performance score to see where each metric falls
                without leaving the page.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-25/ebeb35e1-1410-4952-aac1-408887725227/screenshot.jpeg?tl_px=0,0&br_px=767,601&force_format=png&width=1120.0"
                alt="Performance score breakdown in Lighthouse"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The Lighthouse documentation{" "}
                <Link
                    textDecoration="underline"
                    to="https://developer.chrome.com/docs/lighthouse/performance/performance-scoring"
                    target="new"
                >
                    recommends
                </Link>{" "}
                having a score of 90-100 to be considered good. However, it notes a score of 100 is "extremely
                challenging to achieve and not expected," as it gets harder and harder to increase your score the higher
                it is.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                There is no distinction made in the documentation between types of websites that require more dynamic
                content, such as an online store, so eCommerce platforms like Shopify have{" "}
                <Link
                    textDecoration="underline"
                    to="https://www.shopify.com/enterprise/blog/site-speed-score"
                    target="new"
                >
                    stated
                </Link>{" "}
                that{" "}
                <strong>
                    while Lighthouse scores can be helpful indicators for improvement, they are not the definition of
                    success.
                </strong>
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Metrics
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Each metric score is designated as "Good" (90-100 points in green), "Needs Improvement" (50-89 points in
                yellow), or "Poor" (0-49 points in red). The metrics include Core Web Vitals, as well as other elements
                of a page's loading speed.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://developer.chrome.com/docs/lighthouse/performance/first-contentful-paint"
                            target="new"
                        >
                            First Contentful Paint (FCP)
                        </Link>
                    </strong>{" "}
                    tracks the time it takes for the first bit of content to become visible to a user and occurs before
                    your Largest Contentful Paint (LCP). This time is important to consider as it marks the first moment
                    your visitors perceive the page response.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://www.nostra.ai/blogs-collection/largest-contentful-paint-lcp"
                            target="new"
                        >
                            Largest Contentful Paint (LCP)
                        </Link>
                    </strong>{" "}
                    indicates when the largest element on a given page (usually a photo or video in the eCommerce world)
                    becomes visible to your customers. LCP measures the time it takes the element to render and begins
                    when a user requests the page via its URL.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://developer.chrome.com/docs/lighthouse/performance/lighthouse-total-blocking-time"
                            target="new"
                        >
                            Total Blocking Time (TBT)
                        </Link>
                    </strong>{" "}
                    measures how long your main thread is blocked long enough to interfere with the user experience.
                    This metric begins after your FCP as there is no user interaction until the UI is visible, and
                    captures any task, such as JavaScript execution or HTML parsing, that is longer than 50 milliseconds
                    and would make the user perceive the page as slow.
                </ListItem>
                <Alert status="warning" mb={"spacer-6"}>
                    <AlertIcon />
                    <Text>
                        Total blocking time counts for 30% of your overall score, so be sure to examine your code for
                        efficiency improvements and assess third-party scripts such as chat apps, upsell apps, or
                        analytics apps for long processing times, low usage, etc.
                    </Text>
                </Alert>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://www.nostra.ai/blogs-collection/cumulative-layout-shift-cls-how-to-measure-optimize-cls"
                            target="new"
                        >
                            Cumulative Layout Shift (CLS)
                        </Link>
                    </strong>{" "}
                    refers to how much a page's layout or content moves during a customer's visit. A score of 0 means no
                    shifting is present, so the closer your score is to 0, the better your user experience will be.{" "}
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://developer.chrome.com/docs/lighthouse/performance/speed-index"
                            target="new"
                        >
                            Speed Index (SI)
                        </Link>
                    </strong>
                    &nbsp;gauges how quickly content appears on the page. Unlike FCP or LCP, it does not track a
                    specific milestone or core element. Instead, the documentation{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/lighthouse/performance/speed-index"
                        target="new"
                    >
                        says
                    </Link>{" "}
                    Lighthouse records a video of the page loading and "computes the visual progression between frames."
                </ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Variability
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    Your overall performance score and individual metric scores will fluctuate, even if you run two
                    reports back to back without making any changes.
                </strong>{" "}
                This apparent inconsistency takes place for several reasons, largely due to differences in technology
                across networks and websites.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The most common causes of score variability include:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Page logic that changes content between users</strong> (A/B tests, advertisements, popups,
                    etc.)
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Varying strength and efficacy of local networks,</strong> which could be caused by lower
                    quality routers, multiple devices sharing bandwidth, etc.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Long-distance network latency,</strong> which could mean moving from one international
                    network to another
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Server or browser delays</strong>
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Third-party applications running in conflict with Lighthouse,</strong> such as browser
                    extensions or antivirus software
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As a result of these factors, our speed experts recommend running multiple Lighthouse tests across a
                single page to better understand your site's performance. Especially when it comes to determining if a
                change has improved your site's performance, run at least a few tests and consider the impact of these
                factors.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Treemap And Visual Load
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Selecting "View Treemap" beneath your field metrics will take you to a visualization of the largest
                scripts your site is using.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-25/6c913d75-1aa1-4228-803b-b3b7e3e4cac1/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=955,222&force_format=png&width=1120.0"
                alt="Treemap button and visual load images"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Use the treemap to analyze which scripts may be blocking other vital resources by considering the
                following questions:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    Do you recognize all the scripts listed and understand why they are present on your site?
                </ListItem>
                <ListItem pb={"spacer-4"}>Do you have a significant number of unused bytes?</ListItem>
                <ListItem pb={"spacer-6"}>
                    Are all the code dependencies present (indicated by smaller boxes within larger boxes) necessary?
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Lighthouse only assesses the page's initial load, so it's possible unused bytes will be activated later
                based on user interactions, popups, etc. Watch for scripts from third party applications you no longer
                use, and consider alternatives to the order of scripts present. For example, could a script be deferred
                if it's mostly unused in your page's first load?
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-25/1a2ace87-3859-4f23-9ead-59d178b75464/screenshot.jpeg?tl_px=0,5&br_px=1529,966&force_format=png&width=1120.0"
                alt="Treemap for Amazon.com"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Following the Lighthouse Treemap option is a series of images representing your initial page load. This
                can help you see what a site visitor might see when they arrive at your site or page.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-25/50e59c40-0ab0-4427-9f3e-91de2c30fc83/screenshot.jpeg?tl_px=0,0&br_px=957,218&force_format=png&width=983"
                alt="Treemap for Amazon.com"
            />
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    As Lighthouse is a lab environment,{" "}
                    <strong>any information you see in the report represents a single page load experience.</strong> You
                    can see the lab setup of your test by scrolling to the bottom of the report, where you will find a
                    breakdown of the testing environment.
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-25/5786d3e4-4b91-479f-bde4-b9364695edb2/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=667,100&force_format=png&width=688"
                alt="Lighthouse testing environment"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Diagnostics
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The "Diagnostics" section contains a list of more nuanced audits that the page in question did not pass.
                You can filter the list by selecting FCP, LCP, TBT, or CLS to only see the audits that contribute to
                those metrics or view all of the recommendations together.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    There is only an "indirect relationship" between the diagnostics listed and your page score,
                    according to the{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/lighthouse/performance/performance-scoring/?utm_source=lighthouse&utm_medium=lr"
                        target="new"
                    >
                        documentation
                    </Link>
                    . This means while taking advantage of your diagnostic recommendations <i>could</i> improve your
                    page score because they improve a metric, the only guaranteed influences are the metrics themselves.
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-25/f3fc836d-da6d-43dd-aedf-726c137e5d16/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=739,347&force_format=png&width=860"
                alt="Lighthouse diagnostics section"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Selecting a specific opportunity like “Largest Contentful Paint element” will provide you with more
                information on why that particular audit failed. In some cases, the descriptions of the audit will
                contain suggestions on how to make improvements. For those that do not, click on the “Learn more”
                hyperlinks to get a better understanding of what you are seeing.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                In the case of the LCP element, the dropdown reveals the phases that contribute to your largest
                contentful paint.
            </Text>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    According to {BRAND_NAME}'s speed experts, once your TTFB is optimized, a common cause of slow LCP
                    times is not properly prioritizing your largest, immediately visible element. Never lazy load your
                    above-the-fold media; consider adding a high fetch priority and eager loading instead.
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-24/14189090-6219-4d57-ac0a-e3c58994b094/user_cropped_screenshot.jpeg?tl_px=0,2&br_px=691,551&force_format=png&width=983"
                alt="Lighthouse LCP element breakdown"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Each audit can provide you with valuable information about the specific stylesheets, scripts,
                third-party apps, and media elements that negatively affect your page's overall performance.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Below the "Diagnostics" section, Lighthouse displays your "Passed Audits" to indicate what the page is
                doing well. These same audits are present in any Lighthouse "Performance" report, although they will, of
                course, land in different places based on the specific page being assessed.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-05/5ad59816-15ac-4973-b597-139852c91a4c/screenshot.jpeg?tl_px=0,0&br_px=721,332&force_format=png&width=860"
                alt="Lighthouse passed audits"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="nostra">
                {BRAND_NAME} + Lighthouse
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Running Lighthouse reports is a great way to see {BRAND_NAME}'s cutting-edge solutions in action. You
                can observe your original site and your site with {BRAND_NAME} side by side in a lab environment by
                adding our testing parameter.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    As with any metrics related to {BRAND_NAME}, keep in mind our products are optimization tools. If
                    your website updates its third-party applications, product images and slideshows, etc., those
                    changes could impact your scores unrelated to your performance gains with {BRAND_NAME}.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                All you have to do is open two new tabs in your web browser. In the first tab, enter the URL of the page
                you want to test and run a report through Lighthouse. Assuming {BRAND_NAME} is currently active on your
                site, this will be the performance score of that page for human users with {BRAND_NAME} enabled.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To see your site without {BRAND_NAME} enabled, add <Code>?skipCache</Code> to the same URL in your other
                tab and run a new Lighthouse report. For example: <Code>https://demostore.nostra.ai/?skipCache</Code>.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Not only can you see the difference {BRAND_NAME} is making on your site from a controlled environment,
                but you can get additional ideas on metrics you could improve to boost your site performance.
            </Text>
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Note On Lighthouse Defaults
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Typical Lighthouse tests measure lab data for bot users and analyze a first-load experience before any
                content has been cached. However, {BRAND_NAME} has configured the system to deliver the {MAIN_PRODUCT}{" "}
                version of your site for real human users to Lighthouse by default and has provided parameters so you
                can adjust your tests according to what you need.
            </Text>
            <TableContainer pb={"spacer-6"}>
                <Table variant="striped" colorScheme="gray" size={"md"} whiteSpace={"pre-wrap"}>
                    <Thead>
                        <Tr>
                            <Th>Parameter</Th>
                            <Th>Description</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>
                                <Code bg="white">?skipSeo</Code>
                            </Td>
                            <Td>
                                Ensures you receive the human version of your site with {BRAND_NAME}'s {MAIN_PRODUCT}{" "}
                                enabled—the default behavior
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Code>?forceSeo</Code>
                            </Td>
                            <Td>
                                Ensures you receive the bot version of your site with {BRAND_NAME}'s {SECONDARY_PRODUCT}{" "}
                                enabled, if applicable to your store
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Code bg="white">?skipCache</Code>
                            </Td>
                            <Td>Ensures you receive your original site</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Assuming {BRAND_NAME} is enabled on your site, Lighthouse will assess the page as a real human user
                    and receive the advanced caching benefits of {MAIN_PRODUCT} on any default run.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Due to the different optimizations required for bot versus human users,{" "}
                <strong>
                    {SECONDARY_PRODUCT} scores will likely be the highest as your content is presented in its simplest
                    form for bots, followed by {MAIN_PRODUCT} scores for human users, and then your original site for
                    human users.
                </strong>{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Scores will vary widely depending on your content, but as an example, one of our customers had the
                following overall scores in Lighthouse:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>{SECONDARY_PRODUCT}: 100</ListItem>
                <ListItem pb={"spacer-4"}>{MAIN_PRODUCT}: 75</ListItem>
                <ListItem pb={"spacer-6"}>Original site: 68</ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Visit our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_THIRD_PARTY_DEFAULTS} target="new">
                    Understanding Testing Defaults With {BRAND_NAME} documentation
                </Link>
                &nbsp;for more information.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
