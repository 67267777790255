import { PageContainer } from "../../PageContainer";
import { Text, Box, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const CustomerJourneyMetrics = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Metrics"}
                menuItem1Link={ROUTES.DOCUMENTATION_METRICS_ROI}
                menuItem2={`Customer Journey Metrics`}
            />
            <Header pb={"spacer-8"}>Customer Journey Metrics</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                So many factors come into play throughout a customer's journey with your site, and not all of them will
                be affected by optimization and speed. {BRAND_NAME} may not touch every metric that is important to you,
                such as upsells or offsite advertisements. However, we do expect our core products to make what you have
                more efficient so your customers are more committed throughout the shopping process.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For example, Deloitte's{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://www.deloitte.com/content/dam/Deloitte/ie/Documents/Consulting/Milliseconds_Make_Millions_report.pdf"
                    }
                    target="new"
                >
                    Milliseconds make Millions
                </Link>{" "}
                study found that a 0.1-second gain in site speed created a "positive change in progression through the
                purchase funnel on mobile devices."
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The study{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://www.deloitte.com/content/dam/Deloitte/ie/Documents/Consulting/Milliseconds_Make_Millions_report.pdf"
                    }
                    target="new"
                >
                    reported
                </Link>{" "}
                a{" "}
                <strong>
                    0.1-second speed optimization led to a 3.2% increase in customers moving from product listing pages
                    to product pages and a 9.1% increase in add-to-carts.
                </strong>
                &nbsp;The result? The brands in the study saw their customers spend 9.2% more on average.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Amazon conducted a{" "}
                <Link
                    textDecoration="underline"
                    to={"https://www.conductor.com/academy/page-speed-resources/faq/amazon-page-speed-study/"}
                    target="new"
                >
                    study
                </Link>{" "}
                that also supported the benefits of speed on revenue, sharing that{" "}
                <strong>for every 100 milliseconds in page load delay, there was a 1% cost in revenue.</strong>{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s speed experts recommend monitoring your abandonment and engagement rates when it comes to
                assessing the impacts of the {MAIN_PRODUCT} on your eCommerce funnel.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Abandonment Rate
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Abandonment rate refers to customer actions that are abandoned along the user journey. Your abandonment
                rate at several steps along the way could be reduced because of {BRAND_NAME}.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                While you may receive additional or fewer customer actions depending on the context and time of year,
                since your site is more efficient with the {MAIN_PRODUCT}, regardless of the overall counts, we expect
                more customers to reach the end of that action because they are having a better experience.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Landing page platform Unbounce{" "}
                <Link textDecoration="underline" to={"https://unbounce.com/page-speed-report/"} target="new">
                    surveyed
                </Link>{" "}
                750 consumers and 395 marketers and found that{" "}
                <strong>almost 70% of customers said page speed would influence their "likeliness to buy."</strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Regarding page loading times of 3 seconds, Unbounce also{" "}
                <Link textDecoration="underline" to={"https://unbounce.com/page-speed-report/"} target="new">
                    found
                </Link>{" "}
                that about 50% of customers would refresh the page, but "22% say they'll close the tab, and 14% say
                they'll visit a competitor's site. If you count on 1,000 daily visitors, that's 360 potential customers
                gone."
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google's{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://www.thinkwithgoogle.com/consumer-insights/consumer-trends/mobile-site-load-time-statistics/"
                    }
                    target="new"
                >
                    findings
                </Link>{" "}
                were even more compelling, as seen in the graphic below:
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-25/cd26d67c-91af-4816-bb9f-91c5f8196df2/screenshot.jpeg?tl_px=0,0&br_px=1440,800&force_format=png&width=1120.0"
                alt="Google graphic: 53% of visits are abandoned if a mobile site takes longer than 3 seconds to load"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Meanwhile, online retailer Rakuten 24{" "}
                <Link textDecoration="underline" to={"https://web.dev/case-studies/rakuten"} target="new">
                    found
                </Link>{" "}
                that optimizing their site's performance led to a <strong>35.12% reduction in abandonment rate.</strong>
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Engagement Rate
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Engagement rate is the inverse of your bounce rate, tracking how actively your customers interact with
                your site. With speed optimizations in place, your engagement rate should increase while your bounce
                rate decreases.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google conducted a{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://www.thinkwithgoogle.com/marketing-strategies/app-and-mobile/mobile-page-speed-new-industry-benchmarks/"
                    }
                    target="new"
                >
                    study
                </Link>{" "}
                on 11 million mobile ads' landing pages and found that{" "}
                <strong>slower page load times increased customer bounce rates by up to 123%.</strong> The retail
                industry was highlighted as one of the fields with the greatest room for improvement.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-25/42f4c105-a248-4a9e-b449-610cb909cde2/screenshot.jpeg?tl_px=0,0&br_px=1920,1080&force_format=png&width=1120.0"
                alt="Google graphic showing increase in bounce rate as page time increases"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Pfizer took this idea to heart, shifting their focus to mobile speed. They soon{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://www.thinkwithgoogle.com/marketing-strategies/app-and-mobile/mobile-speed-accessibility/"
                    }
                    target="new"
                >
                    discovered
                </Link>{" "}
                that <strong>with a 38% improvement in site load time, their bounce rate decreased by 20%.</strong>
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Be aware that {BRAND_NAME} cannot give you the same benefits if your content is not up to industry
                    standards. For example, if customers are leaving your site because of misleading descriptions, bad
                    links, erroneous forms, or other common bounce rate contributors, you are less likely to see
                    benefits from {BRAND_NAME}'s core products.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Additionally, remember that context is critical to analyzing your data and performance. How many
                applications or scripts are working together on the same page? What sales or campaigns are in place that
                could influence a particular user journey?{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                While {BRAND_NAME} can work in tandem with the vast majority of apps, it is possible and even probable
                that some apps add weight to your codebase, page load times, or general user journey and lead to worse
                results unrelated to your site speed.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
