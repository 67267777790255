import { PageContainer } from "../../PageContainer";
import { Text, Code, Box, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const TestYourSiteWithoutNostraPage = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Debugging"}
                menuItem1Link={ROUTES.DOCUMENTATION_TURN_OFF_ON}
                menuItem2={"Test Your Site"}
            />{" "}
            <Header pb={"spacer-8"}>Test Your Site</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Troubleshooting is an important step in any business, so our engineers created an easy-to-use tool to
                let you examine your site with and without {BRAND_NAME}.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                All you have to do is open two new tabs in your web browser. In the first tab, enter the URL of the page
                you want to test: <Code>www.examplestore.com</Code>. Assuming {BRAND_NAME} is currently active on your
                site, this will be the page with {BRAND_NAME} enabled.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To see your site without {BRAND_NAME} enabled, all you need to do is add <Code>?skipCache</Code> (or{" "}
                <Code>&amp;skipCache</Code> in some cases—see more below) to the same URL in your other tab:{" "}
                <Code>www.examplestore.com?skipCache</Code>.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    <Code>?skipCache</Code> will not work properly if your URL already has a parameter. If you have a
                    URL parameter in place, use <Code>&amp;skipCache</Code> instead. For example:{" "}
                    <Code>www.examplestore.com/products?size=medium&amp;skipCache</Code>.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Avoid Breaking Your URLs' Parameters
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                URLs are an invaluable resource when it comes to finding content across the web. They often rely on
                parameters, also known as query strings, to further organize and filter content.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can tell when a URL is using a parameter because it will have a <Code>?</Code> followed by some kind
                of key. That key can be a word, like <Code>skipCache</Code>, or it can include a value, like{" "}
                <Code>size=large</Code>.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                In many cases, URLs will have a series of parameters. These series will always start with a{" "}
                <Code>?</Code>, and each new parameter will follow after a separator, which you can recognize as{" "}
                <Code>&</Code>.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For example, a URL with a series of parameters would look like{" "}
                <Code>www.examplestore.com/products?size=medium&amp;color=blue</Code>.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If the page you want to test with and without {BRAND_NAME} enabled already has a URL parameter started,
                be sure to add <Code>skipCache</Code> with a separator, <Code>&amp;</Code>, instead of a parameter
                starter, <Code>?</Code>. For example:{" "}
                <Code>www.examplestore.com/products?size=medium&amp;skipCache</Code>.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                It's as simple as that! Now you can make direct comparisons between your site with and without{" "}
                {BRAND_NAME}.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
