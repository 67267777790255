import { Header, Link } from "../../components";
import { useListAppsByStore } from "../../hooks";
import { VStack, Flex, Avatar, Box, Text, Badge } from "@chakra-ui/react";
import { LoadingPage } from "../LoadingPage";
import { ROUTES } from "../../Routes";
import { generatePath } from "react-router-dom";
import { useMemo } from "react";

type Props = {
    storeId: string;
    customerUrl: string;
};

export const ShopifyAppsList = ({ storeId, customerUrl }: Props) => {
    const useListAppsByStoreQuery = useListAppsByStore(storeId, customerUrl);
    const { data: apps, isLoading } = useListAppsByStoreQuery;

    const alphabetizeAppList = useMemo(() => {
        return apps
            ?.filter((app) => app.app.title)
            .sort((a, b) => {
                const appA = a.app.title.toLowerCase();
                const appB = b.app.title.toLowerCase();
                if (appA < appB) {
                    return -1;
                }
                if (appA > appB) {
                    return 1;
                }
                return 0;
            });
    }, [apps]);

    if (isLoading) {
        return <LoadingPage />;
    }

    return (
        <>
            <Header py={"spacer-8"} as="h3" textStyle="text-header-M">
                Shopify Applications
            </Header>
            {!alphabetizeAppList || alphabetizeAppList.length === 0 ? (
                <Text pb={"spacer-4"}>No applications found. Shopify App Sync requires read_apps permissions.</Text>
            ) : null}

            <VStack alignItems={"baseline"}>
                {alphabetizeAppList?.map((appObject) => {
                    return (
                        <Flex
                            key={appObject.app.id}
                            padding={"spacer-2"}
                            border={"1px"}
                            borderColor={"gray.200"}
                            borderRadius={"0.75rem"}
                            width={"100%"}
                            maxHeight={"110px"}
                            overflow={"auto"}
                        >
                            <Link to={generatePath(ROUTES.SHOPIFY_APP, { appId: appObject.app.id })}>
                                <Avatar
                                    name={appObject.app.title}
                                    src={appObject.app.iconUrl ?? ""}
                                />
                            </Link>
                            <Box ml="3">
                                <Link to={generatePath(ROUTES.SHOPIFY_APP, { appId: appObject.app.id })}>
                                    <Text fontWeight="bold">
                                        {appObject.app.title}
                                        &nbsp;
                                        {appObject.app.appType === 'app' ? (
                                            <Badge>App</Badge>
                                        ) : (
                                            <Badge>Sales Channel</Badge>
                                        )}
                                        &nbsp;
                                        {appObject.deleted === true ? (
                                            <Badge colorScheme="red">Deleted</Badge>
                                        ) : (
                                            <Badge colorScheme="green">Active</Badge>
                                        )}
                                        &nbsp;
                                        {appObject.app.supported === true ? (
                                            <Badge colorScheme="blue">Supported</Badge>
                                        ) : (
                                            <Badge colorScheme="red">Unsupported</Badge>
                                        )}
                                    </Text>
                                </Link>

                                <Text ml="spacer-1">
                                    {appObject.app.description ? appObject.app.description : "No description provided."}
                                </Text>
                            </Box>
                        </Flex>
                    );
                })}
            </VStack>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </>
    );
};
