import { Badge, Card, CardBody, Text, useMediaQuery, VStack } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { VirtualizedDataTable } from "../../../components";
import { Store, StoreHistoryRow } from "../../../types";
import { createColumnHelper } from "@tanstack/react-table";
import { formatDateTimeDisplay, storeModeBadge, truncate } from "../../../lib";
import { useStoreModeHistoryLog } from "../../../hooks";
import { LoadingPage } from "../../LoadingPage";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

type Props = {
    store: Store;
};

export const StoreHistoryLog = ({ store }: Props) => {
    const { data: storeHistoryRows, isLoading } = useStoreModeHistoryLog(store.id, store.customerUrl);
    const [rows, setRows] = useState<StoreHistoryRow[]>([]);
    const [isMobile] = useMediaQuery("(max-width: 744px)");

    const columns = useMemo(buildColumns, []);

    useEffect(
        function setHistoryRows() {
            if (!storeHistoryRows) return;
            setRows(storeHistoryRows);
        },
        [storeHistoryRows],
    );

    if (isLoading) return <LoadingPage />;

    return (
        <>
            {storeHistoryRows.length === 0 ? (
                <Text
                    textStyle={{
                        base: "text-header-S",
                        md: "text-header-M",
                        lg: "text-header-L",
                    }}
                    pb="spacer-2"
                >
                    No History Found
                </Text>
            ) : (
                <VStack pt="spacer-6">
                    <Text
                        textStyle={{
                            base: "text-header-S",
                            md: "text-header-M",
                            lg: "text-header-L",
                        }}
                        pb="spacer-2"
                    >
                        History
                    </Text>
                    <Text margin={{ base: "auto", lg: "0" }} fontWeight={"bold"}>
                        All times are your local time zone.
                    </Text>
                    {isMobile ? (
                        <div style={{ height: "calc(90vh)", width: "100%", paddingTop: "1rem" }}>
                            <AutoSizer>
                                {({ height, width }: { height: number; width: number }) => {
                                    const ITEM_SPACING = 8;
                                    const ITEM_SIZE = 127;
                                    return (
                                        <List
                                            height={height}
                                            width={width}
                                            itemCount={storeHistoryRows.length}
                                            itemSize={ITEM_SIZE + ITEM_SPACING}
                                        >
                                            {({ index, style }) => (
                                                <VStack
                                                    width="100%"
                                                    align={"center"}
                                                    className="alternate-bg-card"
                                                    display={{ base: "block", lg: "none" }}
                                                >
                                                    <Card
                                                        width={"100%"}
                                                        size="sm"
                                                        key={storeHistoryRows[index].id}
                                                        style={{
                                                            ...style,
                                                            height: ITEM_SIZE,
                                                            backgroundColor:
                                                                index % 2 === 0
                                                                    ? "var(--chakra-colors-gray-100)"
                                                                    : "white",
                                                        }}
                                                    >
                                                        <CardBody>
                                                            <Text fontWeight={"bold"} display="inline-block">
                                                                {formatDateTimeDisplay(storeHistoryRows[index].date)}
                                                            </Text>
                                                            &nbsp;
                                                            <Text>
                                                                {truncate(
                                                                    JSON.stringify(
                                                                        storeHistoryRows[index].entry,
                                                                        null,
                                                                        2,
                                                                    ),
                                                                    90,
                                                                )}
                                                            </Text>
                                                        </CardBody>
                                                    </Card>
                                                </VStack>
                                            )}
                                        </List>
                                    );
                                }}
                            </AutoSizer>
                        </div>
                    ) : (
                        <VirtualizedDataTable columns={columns} data={rows} />
                    )}
                </VStack>
            )}
        </>
    );
};

const buildColumns = () => {
    const columnHelper = createColumnHelper<StoreHistoryRow>();
    const columns = [
        columnHelper.accessor("date", {
            cell: (props) => formatDateTimeDisplay(props.getValue()),
            header: "Date",
        }),
        columnHelper.accessor("user", {
            cell: (props) => <Text>{props.getValue()}</Text>,
            header: "User",
        }),
        columnHelper.accessor("status", {
            cell: (props) => {
                const { label, color } = storeModeBadge(props.getValue());
                return <Badge colorScheme={color}>{label}</Badge>;
            },
            header: "Status",
        }),
        columnHelper.accessor("reason", {
            cell: (props) => <Text>{props.getValue()}</Text>,
            header: "Reason",
        }),
    ];
    return columns;
};
