import { PageContainer } from "../../PageContainer";
import { Text, Box, Alert, AlertIcon, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, Link } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const SearchEngineRankingMetrics = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Metrics"}
                menuItem1Link={ROUTES.DOCUMENTATION_METRICS_ROI}
                menuItem2={`Search Engine Page Ranking`}
            />
            <Header pb={"spacer-8"}>Search Engine Page Ranking</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Before diving into the benefits {BRAND_NAME} can provide for your search engine page ranking, remember
                that your content-based and technical SEO practices must be in place before speed optimizations can come
                into play.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google, for example, determines its search engine results pages (SERPs){" "}
                <Link
                    textDecoration="underline"
                    to={"https://www.google.com/search/howsearchworks/how-search-works/ranking-results/"}
                    target="new"
                >
                    based on the following factors
                </Link>
                :
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>The meaning of the user's search query</ListItem>
                <ListItem pb={"spacer-4"}>The relevance of your site's content to that query</ListItem>
                <ListItem pb={"spacer-4"}>The quality of your content</ListItem>
                <ListItem pb={"spacer-4"}>The usability of your content</ListItem>
                <ListItem pb={"spacer-6"}>
                    The context of the user who made the request, such as their location
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Every website competes to be at the top of the SERP, so if your site is updated with the necessary
                keywords, meta tags, alt text, etc., you will be tied with the other sites that have ensured they have
                the same basic SEO standards.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This is where {BRAND_NAME} comes in, as the winner is now chosen based on factors like quality of
                content and page usability, where a faster experience is prioritized. In other words, speed can act as a
                tiebreaker that allows you to move up to the next rank.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Moz conducted a{" "}
                <Link
                    textDecoration="underline"
                    to={"https://moz.com/blog/how-website-speed-actually-impacts-search-ranking"}
                    target="new"
                >
                    study
                </Link>{" "}
                of 2,000 random search queries, the top 50 results for each search, and the 100,000 resulting pages and
                discovered that—while overall page load can be hard to measure when it comes to SERP rankings—a{" "}
                <strong>
                    "clear correlation was identified between decreasing search rank and increasing time-to-first-byte."
                </strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                In other words, your overall page load improvements and 5-10 time TTFB reductions with {BRAND_NAME}'s
                &nbsp;{MAIN_PRODUCT} can help you beat tiebreakers and move up a rank.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    {BRAND_NAME} cannot move you up the SERP if your site has poor SEO practices in place. If you have
                    good SEO, {BRAND_NAME} can increase your ranking and save you time and money on the engineering
                    resources that would otherwise be needed to break tiebreakers.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This boost doesn't just apply to Google. As early as 2017,{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://about.fb.com/news/2017/08/news-feed-fyi-showing-you-stories-that-link-to-faster-loading-webpages/"
                    }
                    target="new"
                >
                    Facebook's News Feed
                </Link>{" "}
                began considering page speed when displaying sites higher or lower on the page, as Facebook found
                that&nbsp;
                <strong>
                    "as many as 40 percent of website visitors abandon a site after three seconds of delay."
                </strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Pinterest also honed in on page load time and server-side performance and{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://medium.com/pinterest-engineering/driving-user-growth-with-performance-improvements-cfc50dafadd7#.wwimdmkpp"
                    }
                    target="new"
                >
                    reported
                </Link>{" "}
                a <strong>"15 percent increase in SEO traffic</strong> and a 15 percent increase in conversion rate to
                signup."
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                SEO experts recommend measuring your site's ranking by the top keywords you follow. If you are already
                tracking your ranking on those keywords, continue to do so. Assuming your SEO is firmly established, the
                rankings of your particular keywords will gradually increase because of {BRAND_NAME}.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
