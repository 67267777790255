import { PageContainer } from "../../PageContainer";
import { Text, Box } from "@chakra-ui/react";
import { Header, SubHeader, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";

export const ManageUsersResource = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Organization"}
                menuItem1Link={ROUTES.DOCUMENTATION_MANAGE_USERS}
                menuItem2={"Manage Users"}
            />
            <Header pb={"spacer-8"}>Manage Users</Header>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="create-users">
                Create Users
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Store owners have the ability to manage the other users in their organization. To do so, click on your
                profile icon and select "Manage Users" from the dropdown menu that appears in a desktop view, or select
                "Manage Users" from the main menu in a mobile view.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-03/4010bfda-3a72-4e8b-add8-2b8ba9974bd1/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=963,349&force_format=jpeg&q=100&width=964&wat_scale=86&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=733,94"
                alt="Manage Users menu option"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you are a store owner, you will be able to create new users for your store, setting their access
                level by designating their role as “Store Administrator” or “Organization Owner.”{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-03-03/347e7631-246a-45b2-ad2e-f82279218780/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1214,825&force_format=jpeg&q=100&width=1120.0"
                alt="Create new user form"
            />
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"} id="manage-users">
                Edit Users
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For existing users, you can manually reset their passwords or disable/enable their access based on their
                current needs within your organization. Additionally, you can update or delete that user.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Please note that a user's email cannot be edited, as our system manages user profiles via email. To
                change a user's email, you must create a new user profile and delete the current user's profile.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-01-16/adaf246b-cb6e-4677-9af3-438cce52d682/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2293,866&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=272,163"
                alt="User list with update selected"
            />
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
