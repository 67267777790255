import { Text, VStack } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { DataTable } from "../../../components";
import { formatDateTimeDisplay } from "../../../lib";
import { ApiTokenHistoryRow } from "../../../types";
import { useApiTokenHistory } from "../../../hooks";
import { LoadingPage } from "../../LoadingPage";

type Props = {
    customerUrl: string;
};

export const StorePermissionsHistoryLog = ({ customerUrl }: Props) => {
    const { data: apiTokenHistoryRows, isLoading } = useApiTokenHistory(customerUrl);
    const [rows, setRows] = useState<ApiTokenHistoryRow[]>([]);
    const columns = useMemo(buildColumns, []);

    useEffect(
        function setHistoryRows() {
            if (!apiTokenHistoryRows) return;
            setRows(apiTokenHistoryRows.rows);
        },
        [apiTokenHistoryRows],
    );

    if (isLoading) return <LoadingPage />;

    return (
        <VStack width="100%" alignItems={"baseline"} pt="spacer-4">
            {apiTokenHistoryRows.rows.length === 0 ? (
                <Text
                    textStyle={{
                        base: "text-header-S",
                        md: "text-header-M",
                        lg: "text-header-L",
                    }}
                    justifyContent={"center"}
                    alignSelf={"center"}
                    style={{ marginTop: "8rem" }}
                >
                    No History Found
                </Text>
            ) : (
                <VStack pt="spacer-6" width={"100%"}>
                    <Text
                        textStyle={{
                            base: "text-header-S",
                            md: "text-header-M",
                            lg: "text-header-L",
                        }}
                        pb="spacer-2"
                        justifyContent={"center"}
                        alignSelf={"center"}
                    >
                        {apiTokenHistoryRows.title ?? "History"}
                    </Text>
                    <Text justifyContent={"center"} margin={{ base: "auto", lg: "0" }} fontWeight={"bold"}>
                        All times are your local time zone.
                    </Text>
                    <DataTable columns={columns} data={rows} />
                </VStack>
            )}
        </VStack>
    );
};

const buildColumns = () => {
    const columnHelper = createColumnHelper<ApiTokenHistoryRow>();
    const columns = [
        columnHelper.accessor("date", {
            cell: (props) => formatDateTimeDisplay(props.getValue()),
            header: "Date",
        }),
        columnHelper.accessor("user", {
            cell: (props) => <Text>{props.getValue()}</Text>,
            header: "User",
        }),
        columnHelper.accessor("message", {
            cell: (props) => <Text>{props.getValue()}</Text>,
            header: "Message",
        }),
    ];
    return columns;
};
