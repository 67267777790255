import { Authenticator, useAuthenticator, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Text, VStack } from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../components";
import { PageContainer } from "../pages/PageContainer";

export const Login = () => {
    const { route } = useAuthenticator((context) => [context.route]);
    const location = useLocation();
    const navigate = useNavigate();
    let from = location.state?.from?.pathname || "/";
    useEffect(() => {
        if (route === "authenticated") {
            navigate(from, { replace: true });
        }
    }, [route, navigate, from]);
    return (
        <PageContainer height="100vh">
            <VStack height="100%" align="center" justify="center">
                <Header textAlign="center" pb="spacer-4">
                    Get started with Nostra
                </Header>
                <Text pb="spacer-4" textAlign="center">
                    Human and machine driven diagnoses and recommendations for accelerating your eCommerce store.
                </Text>
                <View className="auth-wrapper">
                    <Authenticator hideSignUp={true}></Authenticator>
                </View>
            </VStack>
        </PageContainer>
    );
};
