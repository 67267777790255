import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const CruxReportDocumentation = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Third Party Tools"}
                menuItem1Link={ROUTES.DOCUMENTATION_THIRD_PARTY_DEFAULTS}
                menuItem2={`CrUX Report`}
            />
            <Header pb={"spacer-8"}>Chrome User Experience Report</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The{" "}
                <Link textDecoration="underline" to="https://developer.chrome.com/docs/crux" target="new">
                    Chrome User Experience Report
                </Link>{" "}
                is a public dataset that feeds Google's most popular{" "}
                <Link
                    textDecoration="underline"
                    to="https://developer.chrome.com/docs/crux/methodology/tools"
                    target="new"
                >
                    performance tools
                </Link>{" "}
                as well as third-party tools. But it's not just any dataset; it's the official source of Google's Web
                Vitals.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google uses the metrics provided by CrUX to measure and evaluate website performance, making them a
                crucial resource for website owners, web developers, and digital marketers.
            </Text>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Already know the basics? Skip to the{" "}
                    <a href="#crux-metrics" style={{ textDecoration: "underline" }}>
                        CrUX Metrics
                    </a>
                    &nbsp;or{" "}
                    <a href="#crux-nostra" style={{ textDecoration: "underline" }}>
                        CrUX + {BRAND_NAME}
                    </a>
                    &nbsp;section.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Overview
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                CrUX data is field data that is compiled from a segment of Google Chrome users across millions of
                websites, which likely includes your website.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Users who want to analyze a site included in the dataset can visit the{" "}
                <Link textDecoration="underline" to="https://developer.chrome.com/docs/crux/dashboard/" target="new">
                    CrUX Dashboard
                </Link>{" "}
                to view the data in a digestible format without having to worry about doing any coding. This dashboard
                is updated on the second Tuesday of each month.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-14/48afc3f8-5262-41b2-bf86-7bc879456aff/user_cropped_screenshot.jpeg?tl_px=1,0&br_px=860,204&force_format=png&width=860"
                alt="CrUX Dashboard entry page"
                loading="eager"
            />
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Benefits Of CrUX:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Real-user metrics</strong> provide key insights into users' experiences from month to month.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Sites can collect a baseline</strong> that can be used alongside other field and lab data
                    tools.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Viewers assess real human experiences</strong> from users relying on Chrome that directly
                    determine their Core Web Vitals.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Limitations Of CrUx:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Users are limited.</strong> Users must meet{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/crux/methodology"
                        target="new"
                    >
                        certain criteria
                    </Link>{" "}
                    to be included in the dataset, such as using a desktop or Android version of Chrome. Chrome on iOS,
                    for example, is not supported. Sites must also be indexed and be "sufficiently popular," per
                    the&nbsp;
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/crux/methodology"
                        target="new"
                    >
                        documentation
                    </Link>
                    .
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Not all websites are tracked through CrUX.</strong> To be included, websites must be
                    available to the public and receive enough traffic to result in statistically significant data.{" "}
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>CrUX can only monitor public data,</strong> so a logged-in experience, for example, would
                    not be reflected in CrUX.
                </ListItem>
            </UnorderedList>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    To verify your site is publicly discoverable, run a Lighthouse test and check the SEO audit results.
                    If you see "Page is blocked from indexing" or "Page has unsuccessful HTTP status code audits," we
                    recommend updating your site ASAP.
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-22/0981712e-72c8-49ff-9cd5-a98e40395b54/screenshot.jpeg?tl_px=0,0&br_px=850,439&force_format=png&width=860"
                alt="Example Lighthouse report"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Navigating The Dashboard
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                When you first visit your CrUX Dashboard, you will see your <strong>Core Web Vitals.</strong> You will
                have the option to filter by month and device type, although you cannot select the current month. CrUX
                reports are updated on the second Tuesday of each new month.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    The CrUX Dashboard measures a site's{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/crux/methodology/metrics"
                        target="new"
                    >
                        origin
                    </Link>
                    —meaning the entire site—rather than specific pages. If you are interested in page-specific data,
                    consider using a{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/crux/methodology/tools"
                        target="new"
                    >
                        different CrUX tool
                    </Link>
                    .
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-28/7db8a707-e29e-4603-a17f-c136a06e401c/user_cropped_screenshot.jpeg?tl_px=0,16&br_px=1226,861&force_format=jpeg&q=100&width=1120.0"
                alt="Core Web Vitals summary"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The following three sections will give a more in-depth look at each Core Web Vital. The breakdowns
                include a "P75" metric, representing the 75th percentile of your real users and where they fall between
                "good" and "poor" scores.
            </Text>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Learn more about why Google highlights the 75th percentile for better data reliability{" "}
                    <Link
                        textDecoration="underline"
                        to="https://web.dev/articles/defining-core-web-vitals-thresholds#choice_of_percentile"
                        target="new"
                    >
                        here
                    </Link>
                    .
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can switch between device types as needed, but be aware that your P75 value will always be
                calculated from the total device collection.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    For metrics that do not have a P75 value, we advise looking for trends from month to month. In
                    general,{" "}
                    <strong>
                        if you see numbers noticeably shift, consider what may have been added to your site that could
                        have affected your user experience,
                    </strong>{" "}
                    such as a new script, updated theme, or plugin adjustment.
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-13/f08f7758-ef67-4de1-ab53-237d37271000/user_cropped_screenshot.jpeg?tl_px=0,67&br_px=894,837&force_format=png&width=1120.0"
                alt="Interaction to Next Paint graph"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                After the Core Web Vitals come <strong>additional web vitals and real-user metrics,</strong> such as
                time-to-first-byte and DOMContentLoaded. These metrics are displayed in histograms like the Core Web
                Vitals, although most of them do not contain a P75 marker.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The CrUX Dashboard also includes <strong>user reports</strong> that fall outside the official metrics,
                such as "Device Distribution," "Connection Distribution," and "Navigation Type Distribution."
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-14/22ce856d-d042-4a46-b815-0c52560b4ac9/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=887,899&force_format=png&width=1106"
                alt="Device distribution graph"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                CrUX tracks <strong>"Notification Permissions"</strong> as another indicator of how users interact with
                your site. This metric demonstrates how often users respond to requests from a website to display
                notifications in a positive, negative, or neutral way.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Not all websites have features like this in place, so "Notification Permissions" may or may not apply to
                your store. If this metric does not apply to you, you will see "No data" on your graph. Otherwise, you
                will see the percent of users who accepted, dismissed, denied, or ignored your store's permission
                request.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-14/00d48602-a35c-4ecb-a7e8-951736986180/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=890,902&force_format=png&width=1106"
                alt="Notification permissions empty graph"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Note On Additional Tools
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google created several tools that use CrUX data in different ways. These tools may give you access to
                other metrics and dimensions that the CrUX Dashboard does not show you. For example, the CrUX Dashboard
                does not support the country dimension, so you cannot slice the data by location in the same way you
                could with CrUX on BigQuery.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To learn more about the other CrUX tools available, visit the{" "}
                <Link
                    textDecoration="underline"
                    to="https://developer.chrome.com/docs/crux/methodology/tools"
                    target="new"
                >
                    Chrome UX Report documentation
                </Link>
                .{" "}
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="crux-metrics">
                CrUX Metrics
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The CrUX metrics in the dashboard serve as a visual display of monthly trends and can show data as far
                back as 2017. Most of these reports use histograms and can be read in a similar way. We'll use the
                Cumulative Layout Shift histogram from a real eCommerce website below.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The P75 value serves as your overall score for the Core Web Vitals specifically, representing the 75th
                percentile of your real users' experiences while minimizing outliers that could skew the data.{" "}
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    For metrics that do not have a P75 value, we advise looking for trends from month to month. In
                    general,{" "}
                    <strong>
                        if you see numbers noticeably shift, consider what may have been added to your site that could
                        have affected your user experience.
                    </strong>{" "}
                    Did you reimagine your hero image or install an upsell or review app that could have made a
                    difference for better or worse?
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-15/484e094c-6bc0-4449-a14a-d12935fbf14e/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=894,904&force_format=png&width=1108"
                alt="Cumulative layout shift graph"
            />
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Remember, the CrUX Dashboard data is an aggregation based on the last 28 days of each month and{" "}
                    <strong>will not reflect</strong> your most recent updates until the next month is updated. As an
                    additional note, the percentile values displayed in the CrUX Dashboard, per the{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/crux/methodology/tools"
                        target="new"
                    >
                        documentation
                    </Link>
                    , are "calculated from coarse histogram data so are approximate."
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    The following metrics are included in the CrUX Dashboard and are of interest to eCommerce stores.
                </strong>{" "}
                The first three make up the Core Web Vitals, while the others provide a broader view of your user
                experience in all its complexities.{" "}
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Largest Contentful Paint (LCP)</strong> indicates when the largest element on a given page
                    (usually a photo or video in the eCommerce world) becomes visible to your customers. LCP measures
                    the time it takes the element to render and begins when a user requests the page via its URL.
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-22/00976b95-ec29-4b36-92bd-a4d67343be46/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=900,904&force_format=png&width=1116"
                    alt="Largest Contentful Paint graph"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Interaction To Next Paint (INP)</strong> determines a page's level of responsiveness to
                    human interactions throughout its lifespan based on the latency (or delay) in milliseconds for the
                    browser to react to actions like a click or tap. Google{" "}
                    <Link
                        textDecoration="underline"
                        to="https://support.google.com/webmasters/answer/9205520?hl=en"
                        target="new"
                    >
                        says
                    </Link>{" "}
                    the "final INP value is the longest interaction observed, ignoring outliers." A low INP ensures that
                    the page will be reliably responsive at all times.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Cumulative Layout Shift (CLS)</strong> refers to how much a page's layout or content moves
                    during a customer's visit. A score of 0 means no shifting is present, so the closer your score is to
                    0, the better your user experience will be.{" "}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>First Contentful Paint (FCP)</strong> measures when the first DOM element becomes visible to
                    a user and occurs before your LCP. That content could be any text, image, icon, etc. FCP is
                    important to consider as it marks the first moment visitors can visually perceive the page response.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Time-To-First-Byte (TTFB)</strong> follows connection setup time and web server
                    responsiveness by tracking the time it takes a request to respond to and begin downloading your
                    content. This timing includes redirects, the DNS lookup, the request, and more. The CrUX
                    documentation{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/crux/methodology/metrics"
                        target="new"
                    >
                        states
                    </Link>
                    &nbsp;that TTFB data is only collected on full page loads, meaning “the sample size of TTFB can be
                    smaller than other metrics.”
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-22/d2bc4bc1-b3f6-49ec-8a6c-4ada048da7fe/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=879,903&force_format=png&width=1090"
                    alt="TTFB graph"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Device Distribution</strong> lets you see what devices your users rely on between tablets,
                    mobile devices, and desktops.{" "}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Navigation Type Distribution</strong> provides insight into how users navigate around your
                    page. It was added to the dataset in{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/blog/crux-navigation-types"
                        target="new"
                    >
                        March 2024
                    </Link>{" "}
                    and tracks navigation types including reloads, back/forward navigation, and browser caching.
                </ListItem>
                <Alert status="warning" mb={"spacer-6"}>
                    <AlertIcon />
                    <Text>
                        It is important to note that references to caching within the navigation types section refer to
                        the HTTP cache in the browser, <strong>not</strong>
                        &nbsp;{BRAND_NAME}'s advanced {MAIN_PRODUCT} caching.
                    </Text>
                </Alert>
                <ListItem pb={"spacer-4"}>
                    <strong>Connection Distribution</strong> is a CrUX dimension that categorizes visitor connection
                    speeds between offline, slow 2g, 2g, 3g, and 4g. The documentation{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/crux/methodology/dimensions"
                        target="new"
                    >
                        notes
                    </Link>{" "}
                    that most modern users will have a 4g connection.{" "}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Notification Permissions</strong> track how frequently users respond to requests from a
                    website to display notifications. The response options are as follows: accept, deny, ignore, or
                    dismiss.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>First Paint (FP)</strong> indicates the time it takes the browser to render the first pixel
                    for the user. It serves as the initial smallest sign to the user that the page is loading.{" "}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>DOMContentLoaded (DCL)</strong> reports how long it takes for the HTML document and any
                    deferred scripts to be parsed and fully executed. The metric{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.mozilla.org/en-US/docs/Web/API/Document/DOMContentLoaded_event"
                        target="new"
                    >
                        does not account
                    </Link>{" "}
                    for the time it takes stylesheets or images to fully load, but deferred scripts do wait for
                    stylesheets and could add to the resulting time.{" "}
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-05-22/a49ce440-02bf-4a0e-8a7a-a5536d57551a/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=884,889&force_format=png&width=1114"
                    alt="DOMContentLoaded graph"
                />
                <ListItem pb={"spacer-6"}>
                    <strong>Onload (OL)</strong> refers to how long it takes the entire page and all its elements to
                    load. This includes images, stylesheets, and scripts.
                </ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="crux-nostra">
                CrUX + {BRAND_NAME}
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Keep track of your CrUX data and your Core Web Vitals, as both tools serve as valuable site assessors.
                Good metrics can lead to a better page experience, which can, in turn, support better search engine page
                rankings, as page experience is a factor Google considers in its result rankings.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    {BRAND_NAME} can contribute to these benefits and improve your CrUX metrics in several ways.
                </strong>{" "}
                For a full breakdown of the expected impact of {BRAND_NAME}'s {MAIN_PRODUCT}, visit our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_ROI}>
                    ROI Metrics documentation
                </Link>
                . Here are some of the highlights:
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    As with any metrics related to Nostra, keep in mind our products are optimization tools. If your
                    website updates its third-party applications, product images and slideshows, etc., those changes
                    could impact your scores unrelated to your performance gains with Nostra.
                </Text>
            </Alert>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Boost your overall metric scores.</strong> Per Google's{" "}
                    <Link textDecoration="underline" to="https://web.dev/articles/vitals-spa-faq" target="new">
                        documentation
                    </Link>
                    , "Since Core Web Vitals assessments consider the 75th percentile of page visits, having more,
                    well-performing page visits in the dataset will increase the likelihood that the visit at the 75th
                    percentile of the distribution will be within the recommended thresholds." {BRAND_NAME}'s{" "}
                    {MAIN_PRODUCT} speeds up your content to do just that.{" "}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Enhance your website's baseline.</strong> {BRAND_NAME}'s {MAIN_PRODUCT} is especially known
                    for its incredible reduction of TTFB, often resulting in a 5-10x reduction in the time it takes to
                    begin downloading your content. That huge latency decrease benefits every other part of your user
                    experience and site speed!
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Get huge performance gains across the board.</strong> {BRAND_NAME}'s Shopify-based customers
                    have seen overall performance improvements of 20-30%, while Salesforce Commerce Cloud-based
                    customers have seen overall performance improvements of over 40%. While it will take some time to
                    see these gains because of the CrUX Dashboard's update timeline, our clients see clear improvements
                    in their CrUX metrics.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Not sure how {BRAND_NAME} delivers these benefits? Visit our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_EDGE_DELIVERY_ENGINE}>
                    {MAIN_PRODUCT} documentation
                </Link>
                , or{" "}
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    contact support
                </Link>
                &nbsp;with additional questions.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
