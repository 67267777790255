import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon, Code } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const CacheSettingsResource = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Clear Cache"}
                menuItem1Link={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}
                menuItem2={"Custom Cache Settings"}
            />
            <Header pb={"spacer-8"}>Custom Cache Settings</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Configuring the correct cache duration is key when it comes to optimizing large, international sites
                with {BRAND_NAME}.
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                What Is Cache Duration?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>Cache duration</strong> refers to the period of time during which a webpage or a web
                application's content is stored in cache memory. A cache is a temporary storage area that holds
                frequently used content and resources, which can be accessed quickly without reloading them from the
                server.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Keep in mind <strong>your cache must be managed across several layers for maximum impact:</strong>
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Per page</ListItem>
                <ListItem pb={"spacer-4"}>Per currency</ListItem>
                <ListItem pb={"spacer-6"}>Per data center (AKA edge server location)</ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} allows customers to configure specific cache durations for different pages or sections of a
                website based on factors such as the frequency of updates or the importance of the content.
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Why Customize Your Cache Duration?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                A longer cache duration can help reduce server load and improve website performance, but it can also
                result in users seeing outdated content on frequently updated pages. This is because of the time it can
                take a data center in New York City versus a data center in Dubai, for example, to receive visitors to a
                page to keep its data fresh in the cache's memory.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} has assigned a default cache duration for your store to optimize the majority of your
                site's pages regardless of the currency, nearest data center, etc.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>You may benefit from additional customization if your store:</strong>
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    Regularly updates banners, alerts, and sales on various pages (especially the homepage)
                </ListItem>
                <ListItem pb={"spacer-4"}>Receives a high amount of international traffic</ListItem>
                <ListItem pb={"spacer-6"}>Utilizes several currency types regularly</ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The result of customization is the best of all worlds when it comes to your content. You can serve
                longstanding content via your store's intelligently determined cache default while managing the caching
                of rapidly changing pages as needed.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As a result, your homepage can have a 10-minute cache duration—staying fresh in the cache no matter how
                often you make changes. Meanwhile, your other content types can operate from a different cache duration,
                keeping your pages up to date and ready for rapid delivery.
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                How To Customize Your Cache Duration
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To customize your cache duration, click on the "Clear Cache" icon located on your{" "}
                <strong>Stores</strong> page and then choose the "Cache Settings" tab from the{" "}
                <strong>Manage Cache</strong> page.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-27/285b7e3f-e967-47f8-9b89-f7ba6a1c7a32/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1141,719&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=979,88"
                alt="Cache Settings tab"
            />
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    This tab is only available to store owners. If you have admin access to a store and need to set a
                    custom duration, contact your store's owner to receive owner-level permissions.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                From the "Cache Settings" tab, you can set custom cache durations by adding a specific{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_URL_PATHS} target="new">
                    URL path
                </Link>{" "}
                such as&nbsp;
                <Code>/products/example-t-shirt</Code> or <Code>/collections/best-sellers</Code> and determining your
                preferred cache duration by seconds, minutes, hours, days, or weeks.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-27/1261ab20-b15d-4ad7-bbfe-5071de436852/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1142,471&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=439,278"
                alt="Cache Settings path entry"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Your cache can be customized based on the selected URL path on three levels:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Globally</strong>—This is your default setting, which {BRAND_NAME} will intelligently
                    determine for you. If you have any questions or concerns about the preset duration,{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                        target="new"
                    >
                        contact support
                    </Link>
                    .
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>By individual page</strong>—Use this option when you want to set a custom cache duration on
                    the most granular level. An example would be wanting to refresh your homepage's cache (AKA your
                    site's root with a path of <Code>/</Code>) on a more regular basis.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>By a group of pages</strong>—Customize the cache settings of a group of pages by selecting
                    the proper URL path. For example, <Code>/products</Code> would set the cache duration for any URL
                    that included that path, including <Code>/products/example-shirt</Code> and{" "}
                    <Code>/products/example-hat</Code>.
                </ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Edit Or Delete Custom Paths
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Store owners have the option to edit each unique path's cache settings, with the exception of the global
                default. To recommend changes to your default cache duration,{" "}
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    contact support
                </Link>
                . Owners can also delete any custom cache settings by selecting the trash icon. Your homepage and
                global, or default, cache durations cannot be deleted as they are crucial to your site's optimization
                with {BRAND_NAME}.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Beneath your list of custom cache duration paths, you will see your "Cache Settings History." This
                section displays the date and time of the last cache setting change, the user who made the change, and
                the update made.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-27/b7704b09-f943-4ac2-8de2-7fc27c66efc8/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1237,582&force_format=jpeg&q=100&width=1120.0"
                alt="Cache Settings edit/delete options"
            />
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Duration Prioritization
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If a URL contains a partial or exact "starts with" match against one or more paths, the longest matching
                path will be the duration used.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This means if you set <Code>/products/example-t-shirt</Code> to a duration of 7,854 seconds while also
                setting <Code>/products</Code> to a duration of 4,200 seconds, the longer of the two URL paths will
                determine that page's cache duration.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Since <Code>/products/example-t-shirt</Code> is longer, this page would be set to 7,854 seconds.
                However, <Code>/products/example-hat</Code> would only match <Code>/products</Code> and receive a cache
                duration of 4,200 seconds.{" "}
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
