import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Code, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, Link, SubHeader } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const ApiDocumentationPage = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"API"}
                menuItem1Link={ROUTES.DOCUMENTATION_API}
                menuItem2={"API Overview"}
            />
            <Header pb={"spacer-8"}>API Overview</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-4"}>
                {BRAND_NAME}'s API facilitates the immediate placement of either an entire site or specific site pages
                in our queue, enabling you to clear your cache easily and efficiently without manually clicking any
                buttons in the portal.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-4"}>
                The API should primarily be used when you need to make broad changes to your site or have changes that
                must be reflected instantly. For inventory and product changes, your cache will be cleared via our
                intelligent Shopify webhooks or our Salesforce Commerce Cloud polling. Both methods communicate
                with&nbsp;
                {BRAND_NAME}'s {MAIN_PRODUCT} whenever your products or inventory are updated, keeping your content
                fresh and fast.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To learn more about when manually clearing your cache might be helpful, visit the&nbsp;
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}>
                    When And Why To Clear Your Cache documentation
                </Link>
                .
            </Text>{" "}
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Authentication
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-4"}>
                The required authentication will be provided by an API token generated specifically for your site, which
                can be created as needed through the portal.
            </Text>
            <Text textStyle={"text-body-bold"} pb={"spacer-4"}>
                Headers
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-4"}>
                To access the API endpoint,{" "}
                <Code maxWidth={{ base: "95%" }}>https://developer.nostra.ai/v1/cache/purge</Code>, users must include
                two headers in their request:{" "}
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <Code>Authorization</Code>—provides the credentials that authenticate a user's request
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <Code>Store</Code>—the store hostname on which to operate the request
                </ListItem>
            </UnorderedList>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    For successful authentication, the <Code>Store</Code> must exactly match {BRAND_NAME}'s database. To
                    verify your information, visit the <strong>Stores</strong> section in the {BRAND_NAME} portal and
                    match the information in the "STORE" column.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Error Handling
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} uses the following HTTP response codes to communicate the status of your API request:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <Code>201 Created</Code> means your requested token has been successfully generated.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <Code>401 Unauthorized</Code> may result from incorrect headers or mismatched store/token
                    information.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <Code>404 Not Found</Code> may result from a misspelled route.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <Code>500 Internal Server Error</Code> may result from an unexpected error from the server. If this
                    error persists, check {BRAND_NAME}'s{" "}
                    <Link textDecoration="underline" to={"https://status.nostra.ai/"} target="new">
                        status page
                    </Link>{" "}
                    for updates or{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                        target="new"
                    >
                        contact support
                    </Link>
                    .
                </ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Troubleshooting
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The most common errors are due to the following <Code>401 Unauthorized</Code> requests:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Store header mismatch:</strong> Your Store header does not match {BRAND_NAME}'s database.
                    Verify the store URL is exactly the same as what you see listed in the {BRAND_NAME} portal.{" "}
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Bad API token.</strong> Your store does not match the token, and you are sure you are using
                    the correct store URL. Visit your store's "Permissions" tab to regenerate your token and try again.{" "}
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    Contact support
                </Link>
                &nbsp;if you have additional questions or concerns.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
