import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon, OrderedList } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const PercentilesDoc = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Reports"}
                menuItem1Link={ROUTES.DOCUMENTATION_REPORTS}
                menuItem2={"Percentiles"}
            />
            <Header pb={"spacer-8"}>Percentiles</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                A percentile represents a point below which a certain percentage of data falls. Percentiles help people
                compare information and are perhaps most commonly used to communicate about standardized testing. For
                example, if a student scores within the 80th percentile, they scored better than 80% of all the students
                who took the test.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Percentiles are also used in other fields. In healthcare, they may track a child's growth, where a child
                in the 80th percentile for height is taller than 80% of their peers. Similarly, in finance, they may
                highlight income distributions, where a person in the 80th percentile earns more than 80% of the
                population.{" "}
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                What Are Percentiles?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                A simpler way to understand percentiles is to consider them as markers or thresholds that split a
                dataset into 100 parts. This makes it easier to analyze different segments.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                When delving into statistical analysis, a few core concepts should be remembered to ensure clarity and
                precision in applying percentiles.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        Percentiles are calculated by arranging all the data points in ascending order according to the
                        metric.
                    </strong>{" "}
                    In the eCommerce world, this could be order value, loading speed, Largest Contentful Paint, and
                    more. If any data within the population changes, the percentile must be recalculated, unless you are
                    using an approximate percentile. Approximations, which typically rely on population distributions,
                    are common in the eCommerce space, as they are used by large platforms such as Google.{" "}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        The 100th percentile is generally considered to be the maximum value in the dataset,
                    </strong>{" "}
                    while the 0 mark is considered to be the minimum value. This can change depending on the method
                    used, as some methods recognize the 99th and 1st percentiles as the highest and lowest points,
                    respectively.{" "}
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Percentiles are intended to compare the points in a dataset to each other.</strong>{" "}
                    Depending on the percentile chosen, you can see more outliers in the data you analyze.
                </ListItem>
            </UnorderedList>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    Unless your dataset includes data from other eCommerce stores, percentiles will not help you make
                    broader comparisons to competitors. Instead, they should be used to study user behavior,
                    expectations, speed, and overall performance across your site.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Industry Standards
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Understanding the effect of applying different percentiles to a report or graph is essential for
                properly inspecting your store across various analytics platforms and drawing meaningful conclusions.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Since the beginning of its Core Web Vitals program, Google has used the 75th percentile as its baseline
                for defining metric thresholds ranging from "poor" to "good." Google's documentation{" "}
                <Link
                    textDecoration="underline"
                    to="https://web.dev/articles/defining-core-web-vitals-thresholds"
                    target="new"
                >
                    states
                </Link>{" "}
                two reasons the 75th percentile was selected:
            </Text>
            <OrderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        The 75th percentile reflects the most common user experiences within a dataset's population.
                    </strong>
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>
                        The 75th percentile minimizes outliers of the metric in question to prevent significant skews in
                        the data.
                    </strong>{" "}
                    While outliers may still be present in the dataset, they are much less likely to inflate the results
                    than they would at the 90th or 95th percentile.{" "}
                </ListItem>
            </OrderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Generally, any platform that reports Google's Core Web Vitals will use the 75th percentile by default.
                This includes PageSpeed Insights field data (shown in the image below), the Chrome User Experience
                Report, Shopify's Web Performance report, and more.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-22/be065423-ee94-4cca-a07f-6b39ff7c7482/user_cropped_screenshot.jpeg?tl_px=0,8&br_px=953,648&force_format=png&width=1120.0"
                alt="PageSpeed Insights field data metrics"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Some reporting platforms provide access to additional percentiles for speed and revenue reports.&nbsp;
                <strong>
                    {BRAND_NAME}'s internal reports, however, rely on the industry standard of the 75th percentile as a
                    baseline to view your results with minimal anomalies.
                </strong>
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Interpreting Percentiles
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Your store's human visitors will have a range of experiences with your site's content because of
                uncontrollable factors such as the browser they use, the strength of their internet connection, and
                their geographic location. That is what makes percentiles so helpful.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    The percentile value shows the highest score under which the related percentage of users fall.
                </strong>
                &nbsp;Let's use a largest contenful paint (LCP) report from a public CrUX dataset as an example. The
                metrics at the top of the image below represent the most recent month of data, meaning 72.36% of users
                saw a good LCP, and 10.25% of users saw a poor LCP.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google predefines the measurements that determine if a particular metric is "good" or "bad." In the case
                of LCP, Google has{" "}
                <Link textDecoration="underline" to="https://web.dev/articles/vitals" target="new">
                    established
                </Link>{" "}
                that a good score is 2.5 seconds or less once the page begins to load.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Marked as "P75 LCP (All Devices)" to represent the 75th percentile, we can see that 75% of users in the
                example saw an LCP of less than 2,600 milliseconds, indicating the remaining 25% of users saw an LCP of
                more than 2,600 milliseconds. The final breakdown for June 2024 in this example was 72.36% of users with
                a "good" experience, 17.39% of users with a "needs improvement" experience, and 10.25% of users with a
                "poor" experience, as defined by Google.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-31/98ab087e-29fb-4601-9d77-90d4c9fff68e/screenshot.jpeg?tl_px=0,16&br_px=893,657&force_format=png&width=1120.0"
                alt="Clear single page"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Learn more about how CrUX data is collected by visiting the{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_THIRD_PARTY_CRUX}>
                    CrUX Report documentation
                </Link>
                .
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The P75 is an industry standard because it factors out influential edge cases that make it harder to
                understand what most of your users experience, but you may find other percentiles to be helpful in
                examining those cases.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The CrUX Dashboard does not allow users to switch percentiles, but hypothetically, switching to the 95th
                percentile could return a value of around 4,500 milliseconds. This would mean 95% of your users had an
                LCP of less than 4,500 milliseconds, while 5% of users had an LCP of more than 4,500 milliseconds.
                Taking this a step further, imagine a single user had a load time of 10,000 milliseconds, one of the
                slowest times reported. Selecting the 99th percentile would now include this uncommon LCP time and could
                lead to confusion if that report was taken out of context of the whole population.
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Takeaways
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Understanding percentiles and how to read them is a crucial aspect of data analysis across various
                fields, from education and health to finance and eCommerce.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Percentiles provide a meaningful way to interpret and compare data, allowing for insights into the
                broader distribution of values within a dataset. Specifically, in the eCommerce sector, percentiles are
                instrumental in assessing website performance and user experience, with the 75th percentile used as an
                industry standard to reflect a majority user experience.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                By applying percentiles in context, businesses and individuals can gain a clearer perspective on user
                experiences, identify areas for improvement, and make well-informed decisions.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
