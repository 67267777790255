import { PageContainer } from "../../PageContainer";
import { Text, Box, Alert, AlertIcon, Code, Table, Thead, Tbody, Tr, Th, Td, TableContainer } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const WhatNostraReadsFromShopify = () => {
    const codeString = `
    return await client.request(gql\`
      {
        orders(first:\${MAXIMUM_ORDERS_PER_QUERY}, query:"created_at:<='\${sinceDate}' AND channel_id:\${onlineStoreChannelId}", after:"\${cursor}") {
          edges {
            node {
              id
              createdAt
              app {
                name
              }
              tags
              originalTotalPriceSet {
                shopMoney { amount, currencyCode }
                presentmentMoney { amount, currencyCode }
              }
              currentTotalPriceSet {
                shopMoney { amount, currencyCode }
                presentmentMoney { amount, currencyCode }
              }
              totalDiscountsSet {
                shopMoney { amount, currencyCode }
                presentmentMoney { amount, currencyCode }
              }
              customAttributes { key, value }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
   \`);`;

    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Shopify"}
                menuItem1Link={ROUTES.DOCUMENTATION_SHOPIFY_PII}
                menuItem2={`What ${BRAND_NAME} Reads From Your Shopify Store`}
            />
            <Header pb={"spacer-8"}>What {BRAND_NAME} Reads From Your Shopify Store</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                When a new Shopify customer is configured to work with {BRAND_NAME}, our team takes a proactive approach
                to ensure a smooth integration process and to provide phenomenal support at every step. This approach
                not only minimizes potential issues but also maximizes the benefits you'll receive from our core
                products.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As part of the onboarding, our team requests multiple Shopify permissions. These permissions are
                primarily to support the functionality of the webhook we add to your store.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The webhook allows us to clear your cache automatically when updating a product, changing inventory
                levels, or updating a collection. The rest of the permissions help us verify our internal analytics
                against Shopify's data, ensuring accuracy and reliability.
            </Text>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    {" "}
                    For questions about data privacy and security, visit the{" "}
                    <a href="#pii-section" style={{ textDecoration: "underline" }}>
                        Does {BRAND_NAME} Collect PII? section
                    </a>
                    .{" "}
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For a full explanation of each permission and why we need it, see the table below.{" "}
            </Text>
            <TableContainer pb={"spacer-6"}>
                <Table variant="striped" colorScheme="gray" size={"md"} whiteSpace={"pre-wrap"}>
                    <Thead>
                        <Tr>
                            <Th>Shopify Permission</Th>
                            <Th>Why {BRAND_NAME} Needs It</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>Home (Shopify dashboard)</Td>
                            <Td>General partnership login and access point</Td>
                        </Tr>
                        <Tr>
                            <Td>Orders</Td>
                            <Td>Verify {BRAND_NAME}'s analytics with Shopify's</Td>
                        </Tr>
                        <Tr>
                            <Td>View Products</Td>
                            <Td>
                                Product properties such as title, description, and inventory count allow our webhook to
                                flush the cache at the appropriate time, keeping your information up to date as you make
                                changes
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>View Product Cost</Td>
                            <Td>Our webhook will clear your cache when the cost of a product changes</Td>
                        </Tr>
                        <Tr>
                            <Td>Reports</Td>
                            <Td>Verify {BRAND_NAME}'s analytics with Shopify's</Td>
                        </Tr>
                        <Tr>
                            <Td>Dashboards</Td>
                            <Td>Verify {BRAND_NAME}'s analytics with Shopify's</Td>
                        </Tr>
                        <Tr>
                            <Td>Metaobject Definitions</Td>
                            <Td>Mimic how Shopify links customers to their cart currency</Td>
                        </Tr>
                        <Tr>
                            <Td>Metaobject Entries</Td>
                            <Td>Mimic how Shopify links customers to their cart currency</Td>
                        </Tr>
                        <Tr>
                            <Td>Manage and Install Apps and Channels</Td>
                            <Td>Create and view your store's webhook</Td>
                        </Tr>
                        <Tr>
                            <Td>Themes</Td>
                            <Td>
                                Our webhook will clear your cache when you update your theme, so you can see changes
                                live on your site
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>Blog Posts and Pages</Td>
                            <Td>
                                Our webhook will clear your cache when you update blog posts and/or pages, so you can
                                see changes live on your site
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>Domains</Td>
                            <Td>Manage {BRAND_NAME}'s core products across global stores with different domains</Td>
                        </Tr>
                        <Tr>
                            <Td>Manage Settings</Td>
                            <Td>Manage {BRAND_NAME}'s app and webhook</Td>
                        </Tr>
                        <Tr>
                            <Td>View Apps Developed by Staff & Collaborators</Td>
                            <Td>
                                Access to the "Develop Apps" section in Shopify to manage {BRAND_NAME}'s app and webhook
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>Develop Apps</Td>
                            <Td>Create {BRAND_NAME}'s app and webhook and access Shopify's API</Td>
                        </Tr>
                        <Tr>
                            <Td>Enable App Development</Td>
                            <Td>
                                In case you haven't enabled app development on your store, {BRAND_NAME} can enable it
                                for you. This is a one-time enablement that allows {BRAND_NAME} to add the "Develop
                                Apps" permission
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-12/dd9b78cd-b4c3-4d6b-9e26-e00efa1727c6/user_cropped_screenshot.jpeg?tl_px=0,21&br_px=718,502&force_format=png&width=860"
                alt="Enable App Development screen"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="pii-section">
                Does {BRAND_NAME} Collect PII?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} reads order data to verify our analytics against Shopify's, but{" "}
                <strong>we do not collect any personally identifiable information (PII).</strong> Only high-level
                information such as order ID, total amount, discount amount, and order time is obtained.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The exact fields we gather from your store's orders can be seen in the following Shopify GraphQL query,
                which is the same request used in our codebase.{" "}
            </Text>
            <Code
                mb={"spacer-6"}
                padding={"spacer-6"}
                width="100%"
                display="block"
                whiteSpace="pre-wrap"
                overflow={"auto"}
            >
                {codeString}
            </Code>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Additionally, {BRAND_NAME} requests access to your analytics dashboard to help track your store's
                progress and ROI while using our core products.{" "}
                <strong>{BRAND_NAME} does not store nor share private data from your dashboard.</strong>{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you have additional questions or concerns about your data security and privacy, please{" "}
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    contact support
                </Link>
                .
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
