import { PageContainer } from "../../PageContainer";
import {
    Text,
    Box,
    UnorderedList,
    ListItem,
    Alert,
    AlertIcon,
    Code,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT, SECONDARY_PRODUCT } from "../../../config/BRAND_NAME";

export const UnderstandingTestScoresDoc = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Third Party Tools"}
                menuItem1Link={ROUTES.DOCUMENTATION_THIRD_PARTY_DEFAULTS}
                menuItem2={`Understanding Testing Defaults With ${BRAND_NAME}`}
            />
            <Header pb={"spacer-8"}>Understanding Testing Defaults With {BRAND_NAME}</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Testing tools play an important role in the eCommerce world when it comes to measuring your store's
                performance, but it is important to remember the type of data you collect will vary by resource.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Human Versus Bot Traffic
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Lab data is a type of data collected by many popular assessment tools—such as Lighthouse, PageSpeed
                Insights, and WebPageTest—that is gathered in a consistent environment, producing results that help
                developers find, debug, and fix performance concerns.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Lab tests are run with a high level of control from end to end, reflect one predefined "user," and can
                result in different data than you would get when looking at a broad sample of traffic collected via real
                user metrics (RUM) tools.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Whether you are using a tool that collects lab data or field (RUM) data, when using a testing tool, it
                is also crucial to understand if it is approaching your site and presenting itself as a bot or a human.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If the tool presents itself as a bot, even if you are a human who asked it to perform the test, it will
                get your site's bot version—the {SECONDARY_PRODUCT} version—to conduct its tests.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If the tool presents itself as a real human user, it will get your site's human version—the{" "}
                {MAIN_PRODUCT} version—to conduct its tests.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Even if you—a real human user—ask a tool to conduct a test, if that testing tool presents itself as
                    a bot by default, you will get the bot version of your site.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                How Does This Relate To {BRAND_NAME}?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The context of the data you gather is essential when it comes to drawing conclusions about your site's
                performance with our eCommerce Edge Delivery Platform.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} acts as a proxy service, or gateway, between your site and your incoming traffic, which
                consists of either real human users or bots. Being a proxy means {BRAND_NAME} has the permission and
                access required to make enhancements to your content before serving either type of user.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The first step in our platform's process is determining whether a user is a human or a bot.{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-08/e521d3fb-2caf-4c57-8b67-44474b5b6d21/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=690,544&force_format=png&width=983"
                alt="EDE bot detection graphic"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If the user is a bot, they are served prerendered content—the entire page in its simplest, most
                bot-friendly form—from one of our 310+ distributed edge servers via {BRAND_NAME}'s {SECONDARY_PRODUCT},
                when {SECONDARY_PRODUCT} is enabled.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If the user is a real human, they are served the full, interactive page from the nearest edge server via{" "}
                {BRAND_NAME}'s {MAIN_PRODUCT}, our hybrid server-side rendering product.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Due to the different optimizations required for bot versus human users,{" "}
                <strong>
                    {SECONDARY_PRODUCT} scores will be the highest as your content is presented in its simplest form,
                    followed by {MAIN_PRODUCT} scores, and then your original site.
                </strong>{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Scores will vary widely depending on your content, but as an example, one of our customers had the
                following overall scores in Lighthouse:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>{SECONDARY_PRODUCT}: 100</ListItem>
                <ListItem pb={"spacer-4"}>{MAIN_PRODUCT}: 75</ListItem>
                <ListItem pb={"spacer-6"}>Original site: 68</ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Change In Default Delivery
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                By default, the most popular testing tools treat all incoming users as bots. This means you would
                receive the {SECONDARY_PRODUCT} score for your site when visiting one of these tools.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Per Shopify's request for users' clarity, our engineers have configured the following testing tools to
                receive special treatment within {BRAND_NAME}'s system:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Lighthouse</ListItem>
                <ListItem pb={"spacer-4"}>WebPageTest</ListItem>
                <ListItem pb={"spacer-6"}>GTmetrix</ListItem>
            </UnorderedList>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Any other synthetic testing or laboratory testing bots that utilize any of these three testing
                    services or that present their headers as if they are one of these three testing services will
                    receive the same special treatment within {BRAND_NAME}'s system.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    As of March 26, 2024, our system defaults to delivering the {MAIN_PRODUCT} version of your site to
                    these three testing tools
                </strong>
                , ensuring consistency and clarity in measuring.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Any testing tools not listed will follow their usual patterns. Typically, that means a bot approach to
                your site will get bot data, while a human approach will get human data. Check the documentation of your
                preferred testing tool to verify its approach to gathering data.{" "}
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Choose Your Delivery
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Users can{" "}
                <strong>
                    override this default behavior when using {BRAND_NAME} by adding the following parameters
                </strong>{" "}
                to the URL being tested:
            </Text>
            <TableContainer pb={"spacer-6"}>
                <Table variant="striped" colorScheme="gray" size={"md"} whiteSpace={"pre-wrap"}>
                    <Thead>
                        <Tr>
                            <Th>Parameter</Th>
                            <Th>Description</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>
                                <Code bg="white">?skipSeo</Code>
                            </Td>
                            <Td>Ensures you receive the human version of your site with {BRAND_NAME} enabled</Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Code>?forceSeo</Code>
                            </Td>
                            <Td>Ensures you receive the bot version of your site with {BRAND_NAME} enabled</Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Code bg="white">?skipCache</Code>
                            </Td>
                            <Td>Ensures you receive your original site</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can use these parameters at any time to better understand how your site operates with and
                without&nbsp;
                {BRAND_NAME} enabled. You can also combine them to guarantee you see the version of your site you would
                like to measure:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <Code>?skipSeo</Code>: As a human user with {MAIN_PRODUCT}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <Code>?forceSeo</Code>: As a bot with {SECONDARY_PRODUCT}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <Code>?skipSeo&skipCache</Code>: As a human user on your original site
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <Code>?forceSeo&skipCache</Code>: As a bot user on your original site
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Although your Lighthouse, WebPageTest, and GTmetrix tests default to showing the {MAIN_PRODUCT}&nbsp;
                version of your site for human users while using {BRAND_NAME}, you can use these parameters whenever you
                want to switch your view or confirm your expectations.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Entering the URL <Code>www.example.com?forceSeo</Code>, for example, will show you the{" "}
                {SECONDARY_PRODUCT} view of your site, overriding the default.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As a more in-depth example, PageSpeed Insights typically serves a mix of field data from the CrUX report
                and lab data from Lighthouse, meaning the core web vitals reflected {MAIN_PRODUCT}, while the Lighthouse
                scores reflected {SECONDARY_PRODUCT}.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This was confusing to some users, but since {BRAND_NAME} now serves the human version of Lighthouse data
                by default, your PageSpeed Insights reports will bring back the {MAIN_PRODUCT} version of your
                Lighthouse data, unless you use the parameters above.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The parameters will not affect anything besides how your test experiences the site. Adding{" "}
                <Code>?skipSeo</Code> will not affect anything when using Lighthouse, WebPageTest, and GTmetrix as it is
                already the default. However, you might wish to use it when testing with a different tool or simply for
                peace of mind.{" "}
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Troubleshoot
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Occasionally, users may encounter cache misses during testing, resulting in your original site
                performance scores. We recommend running your test multiple times to get the correct data in these
                cases.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                It is also possible to break URL parameters by incorrectly combining them with other URL parameters.
                Visit the{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_TEST_YOUR_SITE_WITHOUT_NOSTRA}>
                    "Avoid Breaking Your URLs' Parameters" section of this user guide
                </Link>{" "}
                for more information.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    Contact support
                </Link>{" "}
                if you have additional questions or concerns.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
