import { PageContainer } from "../../PageContainer";
import { Text, UnorderedList, ListItem, Box, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME, SECONDARY_PRODUCT } from "../../../config/BRAND_NAME";

export const CrawlerOptimizationPage = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Getting Started"}
                menuItem1Link={ROUTES.DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL}
                menuItem2={SECONDARY_PRODUCT}
            />{" "}
            <Header pb={"spacer-8"}>{SECONDARY_PRODUCT}</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s {SECONDARY_PRODUCT} targets the bot traffic from our eCommerce Edge Delivery Platform's
                advanced bot detection and reroutes it to a highly optimized version of your store, prepared explicitly
                for bot standards.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The {SECONDARY_PRODUCT} product utilizes static rendering—a process of server-side prerendering your
                site and applying bot-specific performance optimizations—along with edge caching to reliably deliver
                your site to high-volume bot traffic while minimizing the impact on the earth through reduced CO2
                emissions.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                What Are Bots Doing On My Site?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The beneficial bots referred to here are used to increase searchability, engage website visitors,
                capture analytics data, and more. They are computer programs or applications designed to carry out a
                specific task and come in different forms.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For example, web crawlers—also known as <strong>search engine bots</strong>—are constantly gathering
                data by downloading and indexing content from websites around the world. Search engines then use that
                data to bring the most relevant content to users.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Other bots include:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Chatbots</strong>, which mimic human conversation to support customers
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Monitoring bots</strong>, which ensure financial and other transactions are completed
                    accurately and securely
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Shopping bots</strong>, which scan eCommerce stores to help people find deals, discounts,
                    and personalized recommendations
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Social media bots</strong>, which provide automated services such as breaking news alerts,
                    generating likes and content, etc.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Search engine bots are of particular note in the eCommerce context, as they are sent out to examine
                sites on a regular basis to help put together search results, meaning your store could be mid-crawl at
                this very moment.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Bots provide many important services across the web, and they don't always understand or efficiently
                process JavaScript. Servers must also use compute energy to process the many bots visiting sites around
                the world, releasing CO2 into the environment.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can rest easy knowing your customized store will be putting its best foot forward thanks to{" "}
                {BRAND_NAME}'s {SECONDARY_PRODUCT}.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Why Do You Need Optimization?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Websites that deliver an exceptional user experience are usually interactive, requiring complex
                JavaScript in addition to images and CSS. While the full delivery of JavaScript, HTML, and CSS files is
                of vital importance to the user experience, bots have limited capabilities compared to users.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Not every bot is equipped to process JavaScript, and even the most sophisticated bots like Googlebot can
                produce errors or fail to render your site correctly. Simply put, bots struggle to crawl every piece of
                websites' client-side content, particularly when it comes to JavaScript.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                JavaScript code can slow down even the most up-to-date bots, leading to a poor crawling experience.
                Since Googlebot only has a specific allocation of "time" to spend crawling your site, slower processing
                leads to less of the site being crawled less often.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The solution? Delivering statically rendered pages—the prerendered and bot-optimized versions of those
                pages—to bots, while delivering the fully interactive pages to a website's human visitors.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                How {BRAND_NAME} Statically Renders Your Site
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s {SECONDARY_PRODUCT} product automatically does all the work for you to ensure your site
                is always ready to provide bot-optimized content. The process is as follows:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    {BRAND_NAME}'s bot crawls your site and prerenders your content, utilizing practices recommended in
                    Google's Developer Documentation.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    Your prerendered site is now optimized for bots, according to leading search engine guidelines
                    produced by Google and Microsoft.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    The optimized and prerendered pages are cached and distributed throughout {BRAND_NAME}'s Edge
                    Delivery network, to be delivered from one of our 310+ edge servers that are within 50ms of 97% of
                    the world's population.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    {BRAND_NAME}'s smart cache ensures your content remains updated in the cache. Through our installed
                    webhooks (Shopify) or polling service (Salesforce Commerce Cloud), {BRAND_NAME} automatically
                    invalidates the cache when you make changes and refreshes the stored content.
                </ListItem>
            </UnorderedList>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-08/c614e5db-1e2b-4250-863e-c72cd1979d85/screenshot.jpeg?tl_px=0,0&br_px=1200,630&force_format=png&width=1120.0"
                alt="Crawler Optimization graphic"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Benefits of {SECONDARY_PRODUCT}
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Thanks to {BRAND_NAME}'s {SECONDARY_PRODUCT}, bots will receive a dedicated version of your site—the
                statically rendered page—every time a bot crawls your store.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This strategic allocation leads to consistently high bot scores, as measured by Google and other major
                search engines, and enhanced visibility on search engines. These benefits, in turn, make your content
                more accessible to your target human audience as they browse the web.
            </Text>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Did you know that roughly 80% of the traffic to your site is bots? {BRAND_NAME} prerenders your
                    pages, stores them in the cache, and serves the cache to all the bots, resulting in a small fraction
                    of the compute power that would have been required for each bot to process your site. Not only does
                    that offload a lot of traffic from your store, but it also saves literal tons of CO2 from polluting
                    our planet by reducing the compute needs.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Other benefits include:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Crawl rate optimization</strong>—Google limits your{" "}
                    <Link
                        to={
                            "https://developers.google.com/search/docs/crawling-indexing/large-site-managing-crawl-budget#crawl-capacity-limit"
                        }
                        textDecoration={"underline"}
                        target={"new"}
                    >
                        crawler budget
                    </Link>{" "}
                    of how many pages are crawled based on the allocation of resources required to crawl them. When
                    Google can load and crawl your pages with fewer resources, it can index far more content from your
                    site.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>New products/blog pages show up in searches faster</strong> thanks to your site's optimized
                    crawling.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Rapidly changing content gets into the search index faster</strong>—This includes campaigns,
                    price changes, sales, news, or other frequently updated information on your site.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>It's the green solution for crawling</strong>—We saved an estimated 86 tons of CO2 due to
                    the {SECONDARY_PRODUCT} product in 2024 alone by reducing bot processing time for&nbsp;
                    {BRAND_NAME}'s customers' pages. That is the equivalent of preventing over 90,000 pounds of coal
                    from being burned or taking gas-powered cars off the road for over 200,000 miles!
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Eliminates Javascript limitations and errors specific to bots</strong> that can prevent your
                    site from being crawled properly.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>New products/blog pages show up in searches faster</strong> thanks to your site's optimized
                    crawling.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Enhance global commerce</strong>—While Google is the world's most used search engine, it
                    doesn't dominate the market in all countries. There are many popular international search engines,
                    and their different search engine crawlers have a range of capabilities and limitations. Optimizing
                    your site for these crawlers is the most reliable to know your site can be crawled globally without
                    issues, even by bots that don't support JavaScript.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Bots are unavoidable in today's web landscape. Equip your site with the tools you need to succeed
                by&nbsp;
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    contacting support
                </Link>{" "}
                to discuss enabling {BRAND_NAME}'s {SECONDARY_PRODUCT}.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
