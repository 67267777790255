import { PageContainer } from "../../PageContainer";
import { Text, Box, Alert, AlertIcon, Stack, HStack } from "@chakra-ui/react";
import { Header, SubHeader, Link } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME } from "../../../config/BRAND_NAME";
import { ChatIcon } from "@chakra-ui/icons";

export const TraditionalMetrics = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Metrics"}
                menuItem1Link={ROUTES.DOCUMENTATION_METRICS_ROI}
                menuItem2={`Traditional Metrics`}
            />
            <Header pb={"spacer-8"}>Traditional Metrics</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Traditional metrics include conversion rate, average order value, and revenue per session. These three
                measurements are widely recognized in the eCommerce space and can assist you in honing in on your
                performance with {BRAND_NAME}.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Conversion Rate
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Conversion rate (CVR) is a measure of efficiency that tracks the ratio of order quantity—meaning the
                number of purchase events, not dollars—to the number of sessions. Shopify{" "}
                <Link
                    textDecoration="underline"
                    to={"https://www.shopify.com/blog/calculate-conversion-rate"}
                    target="new"
                >
                    defines
                </Link>{" "}
                this metric as the "percentage of visitors who purchased."
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Your CVR indicates the effectiveness of traffic being driven to your site and measures quantity, not
                revenue.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This means if you run a successful campaign and increase the traffic coming to your site while
                maintaining the same order count as before the campaign, your CVR would go down even though your revenue
                might remain the same.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    When it comes to measuring speed gains, the focus should shift toward understanding if revenue is
                    increasing alongside CVR improvements, as your CVR can fluctuate based on factors unrelated to site
                    performance.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Digital marketing agency Portent spearheaded an{" "}
                <Link
                    textDecoration="underline"
                    to={"https://www.portent.com/blog/analytics/research-site-speed-hurting-everyones-revenue.htm"}
                    target="new"
                >
                    internal study
                </Link>{" "}
                of 20 websites and 27,000+ landing pages and found that{" "}
                <strong>
                    eCommerce conversion rates decrease by "an average of 0.3% for every additional second it takes for
                    your website to load."
                </strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Shopify has{" "}
                <Link textDecoration="underline" to={"https://www.shopify.com/blog/site-speed-importance"} target="new">
                    reported
                </Link>{" "}
                several statistics concerning the impact of speed on CRO, SEO, and loyalty. The most compelling?{" "}
                <strong>
                    "A one-second improvement in website speed can boost mobile user conversions by up to 27%."
                </strong>
            </Text>
            <HStack mb={"spacer-6"} backgroundColor={"blue.100"} p={"spacer-6"} alignItems={"center"}>
                <ChatIcon boxSize={6} />
                <Stack pl={"spacer-4"}>
                    <Text textStyle={"text-header-S"} mb={"spacer-2"}>
                        "The reality is that [{BRAND_NAME}'s] solution just makes sense for a lot of brands that are
                        conversion-focused, we're definitely{" "}
                        <Link
                            textDecoration="underline"
                            to={
                                "https://www.nostra.ai/success-stories/healthycell-decreases-their-load-time-by-23-with-nostra"
                            }
                            target="new"
                        >
                            seeing the impact
                        </Link>
                        ."
                    </Text>
                    <Text textStyle={"text-body-regular"}>
                        —Travis John, Head of Tech Stack / Partner Integrations at Healthycell
                    </Text>
                </Stack>
            </HStack>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Average Order Value
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Average order value (AOV) represents the average amount of each order and can improve because of factors
                like site speed. It is possible for AOV to increase while order quantities decrease and vice versa as
                your products likely range in cost, so look for patterns and consider the factors that play into AOV in
                addition to speed.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Online retailer Rakuten 24{" "}
                <Link textDecoration="underline" to={"https://web.dev/case-studies/rakuten"} target="new">
                    invested in optimizing
                </Link>{" "}
                their performance metrics across the board, resulting in a{" "}
                <strong>15% increase in AOV, a 53% increase in revenue per visitor,</strong> a 33% increase in
                conversion rate, and a 35% reduction in exit rate.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Deloitte's study,{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://www.deloitte.com/content/dam/Deloitte/ie/Documents/Consulting/Milliseconds_Make_Millions_report.pdf"
                    }
                    target="new"
                >
                    Milliseconds make Millions
                </Link>
                , tracked 30 million mobile user sessions across 37 brands and found that{" "}
                <strong>
                    a site speed improvement of 0.1 seconds produced a 9.2% increase in AOV and an 8.4% increase in
                    conversions
                </strong>
                . General user engagement also went up by 5.2%.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    When looking at any revenue metrics, we recommend prioritizing the revenue of the items purchased
                    using their item value without incorporating any discounts for comparative clarity.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Revenue Per Session
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Revenue per session is a comprehensive metric that combines the effects of AOV and CVR, indicating
                whether orders are increasing in quantity and size.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This metric is particularly helpful in measuring {BRAND_NAME}'s performance, as you can filter out
                discounts, returns, upsells, etc. that may be less related to the buying experience as it relates to
                speed. This is especially true when you have other applications in place that influence those features.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Carpe, an antiperspirant-focused Shopify site,{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://performance.shopify.com/blogs/blog/how-carpe-achieved-record-breaking-sales-by-focusing-on-performance-optimization"
                    }
                    target="new"
                >
                    experienced
                </Link>{" "}
                significant Core Web Vital improvements thanks to performance enhancements, leading to a{" "}
                <strong>
                    5% increase in their online store conversion rate, a 10% boost in traffic, and a total revenue
                    increase of 15%
                </strong>
                .
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Always consider the context of the data you are viewing. For example, if you are running a
                buy-one-get-one-free sale, your CVR and quantity of orders might go up, but your revenue might appear to
                decrease, regardless of how efficiently your site is functioning and your sale is proceeding.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If your sessions are being driven organically and are not paid for via ads, you may find revenue per
                session more valuable overall as you may not be concerned with AOV. Similarly, if you are running an A/B
                test, you may primarily want to know if revenue is increasing.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
