import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon, HStack, Stack, VStack } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";
import { ChatIcon } from "@chakra-ui/icons";

export const ShopifyMetricsDocumentation = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Shopify"}
                menuItem1Link={ROUTES.DOCUMENTATION_SHOPIFY_PII}
                menuItem2={`Shopify Analytics`}
            />
            <Header pb={"spacer-8"}>Shopify Analytics</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Every Shopify store can access a basic set of analytics reports within the admin dashboard to analyze
                traffic, revenue, and more. Custom reporting is only available to stores on a more advanced plan, but
                even the baseline options are a good foundation to begin assessing your site's performance, optimization
                opportunities, and success.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Overview
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Most Shopify data is gathered via cookies, which Shopify{" "}
                <Link
                    textDecoration="underline"
                    to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/overview-dashboard"
                    target="new"
                >
                    defines
                </Link>{" "}
                as "small files that are stored on a shopper's device, such as a desktop or smartphone, when they visit
                your online store."
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                When visitors come to your store, Shopify assigns them two cookies: one to identify the device, or
                visitor, and another to track that user's session.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    Sessions{" "}
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/overview-dashboard"
                        target="new"
                    >
                        only last 30 minutes and time out at 12 am UTC
                    </Link>
                </strong>
                , which means a user can end up with multiple sessions and can make sessions appear inflated over the
                number of visitors. Keep this and similar nuances in mind when assessing specific metrics so you
                understand what is being measured.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The Shopify cookies collect information such as the pages visited, products viewed, and the time spent
                on each page, which can provide valuable insights into customers' behavior.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <VStack>
                    <Text>
                        If you cannot access or load your Shopify admin or have other strange issues with your site,
                        check&nbsp;
                        <Link textDecoration="underline" to="https://www.shopifystatus.com/" target="new">
                            Shopify's status page
                        </Link>{" "}
                        to see if there is a recognized issue. You can also refer to your{" "}
                        <Link
                            textDecoration="underline"
                            to="https://help.shopify.com/en/manual/shopify-admin/shopify-admin-overview#alerts-feed"
                            target="new"
                        >
                            Alerts Feed
                        </Link>{" "}
                        by selecting the bell-shaped icon at the top of your Shopify admin for further guidance.
                    </Text>
                    <DocumentationImage
                        src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-06-17/7ad0b285-f9cc-4f9b-8040-1a8bef708d6b/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2670,124&force_format=png&width=1120.0"
                        alt="Shopify alerts icon"
                        loading="eager"
                    />
                </VStack>
            </Alert>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Already know the basics? Skip to the{" "}
                    <a href="#session-counts" style={{ textDecoration: "underline" }}>
                        Note On Session Counts With {BRAND_NAME}
                    </a>
                    &nbsp;or{" "}
                    <a href="#shopify-nostra" style={{ textDecoration: "underline" }}>
                        Shopify Metrics + {BRAND_NAME}
                    </a>
                    &nbsp;section.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Navigating The Analytics Dashboard
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Visiting the Analytics page from the main menu as a Shopify admin will take you to a snapshot dashboard
                of what Shopify considers to be "the most valuable metrics that give you insight into the performance of
                your store and the behavior of your customers." These metrics, such as total sales over time, conversion
                rate over time, and average order value over time, are key indicators of your store's health and can
                help you identify areas for improvement.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                By default the dashboard will show you a view with several reports, showing the current day's data
                compared to the previous day's data. Update the date range or the comparison by selecting the buttons
                located at the top of the page.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-28/c72f9548-07b8-4ccf-9046-21b0c3ccbc96/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=971,878&force_format=jpeg&q=100&width=1120.0"
                alt="Analytics dashboard"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Data for these reports is up to date within a minute of opening the dashboard and can be refreshed by
                reloading the page.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    The reports can{" "}
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/overview-dashboard"
                        target="new"
                    >
                        change over the course of 48 hours
                    </Link>{" "}
                    as Shopify runs additional tests to display only the most relevant information.
                </strong>{" "}
                For example, Shopify tests visitors to remove bot-based traffic. These bot fluctuations are less common
                with {BRAND_NAME} enabled, as our advanced bot-detection filters out bot traffic from the start. See the{" "}
                <a href="#session-counts" style={{ textDecoration: "underline" }}>
                    Note On Session Counts With {BRAND_NAME}
                </a>{" "}
                section for more information.
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    If you see any metrics noticeably shift, consider what may have been added to your site that could
                    have affected your user experience, such as a new script, updated theme, or plugin adjustment. Any
                    change could affect your performance and user experience.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The following reports are included in the dashboard by default. Shopify{" "}
                <Link
                    textDecoration="underline"
                    to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/overview-dashboard"
                    target="new"
                >
                    notes
                </Link>{" "}
                that any metrics listed in the{" "}
                <Link
                    textDecoration="underline"
                    to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/overview-dashboard"
                    target="new"
                >
                    metrics library
                </Link>{" "}
                can be included with advanced customizations, as long as they are only included once.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Gross sales:</strong> Sales revenue before discounts and returns are considered
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Returning customer rate: </strong>The percent of customers who have placed more than one
                    order compared to all ordering customers
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Orders fulfilled: </strong>Number of orders officially reported as fulfilled
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Orders: </strong>Total orders by number across every sales channel
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Total sales over time:</strong> Amount spent across all active sales channels, including
                    shipping, taxes, discounts, and returns
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Total sales breakdown: </strong>A visual display of the factors that weigh into the total
                    sales calculation
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-28/d9e1024d-2788-4352-86f8-c111267e370a/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=792,572&force_format=jpeg&q=100&width=1025"
                    alt="Total sales breakdown"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Total sales by sales channel: </strong>A breakdown of sales by active sales channels
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Average order value over time </strong>including discounts
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Total sales by product </strong>including fees, shipping, and taxes
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Sessions over time </strong>that indicate traffic volume within the online store
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Conversion rate over time:</strong> The percent of sessions that ended with an online store
                    sale
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Conversion rate breakdown: </strong>A breakdown of the sessions that led to a conversion
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Sessions by device type</strong> within your online store
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-06-17/71555b58-bddb-4d36-9010-9293b82d3f27/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1123,558&force_format=png&width=1120.0"
                    alt="Sessions by device type"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Sessions by location </strong>within your online store
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Total sales by social referrer: </strong>The social referrers that brought in sales
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Sessions by social referrer: </strong>The social referrers that brought sessions to your
                    online store
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Top referrers by sessions: </strong>The top external sites that directed sessions to your
                    online store
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Sessions by landing page</strong> where visitors first arrived at your online store
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Customer cohort analysis: </strong> The percent of customers who returned to your store and
                    bought more products, ordered by the month of their first purchase
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-06-17/20b27daa-3461-4355-ae04-89c7bc2e386a/screenshot.jpeg?tl_px=0,0&br_px=1127,561&force_format=png&width=1120.0"
                    alt="Customer cohort analysis"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Sales attributed to marketing: </strong>The value of your total sales that came from
                    trackable marketing campaigns
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Sessions by referrer, </strong>such as social media, search, or direct entries, that sent
                    traffic to your online store
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Total sales by referrer: </strong>The total sales from sessions that were referred by
                    various traffic sources
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Products by sell-through rate:</strong> The "percentage of the total inventory sold during
                    the selected time period," per the dashboard
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>POS staff sales total:</strong> The total number of sales produced by employees at a
                    physical store location
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Total sales by POS location:</strong> The total number of sales per physical store location
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For a full list of custom metrics that can be added to your Analytics dashboard, visit{" "}
                <Link
                    textDecoration="underline"
                    to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/overview-dashboard"
                    target="new"
                >
                    Shopify's documentation
                </Link>
                .
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Default Shopify Reports
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Shopify includes a set of default reports for customers to explore additional analytics, which can be
                found as a sub-menu item under Analytics. Stores with more advanced plans have the ability to add custom
                reports in this section, which can be identified as "created by" anyone other than Shopify.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-28/a076f8a4-7d51-4779-9786-3339eb94c353/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1196,921&force_format=jpeg&q=100&width=1120.0"
                alt="Available reports in Shopify"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The reports are broken into the following categories for easier reference:
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Pay attention to how each individual report gathers and updates data as this could affect how you
                    compare insights to other reports or third-party analytics tools.
                </Text>
            </Alert>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Acquisition reports</strong>{" "}
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/acquisition-reports"
                        target="new"
                    >
                        indicate
                    </Link>{" "}
                    how users arrive at your store and include "Sessions over time," "Sessions by referrer," and
                    "Sessions by location."
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Behavior reports </strong>
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/behaviour-reports"
                        target="new"
                    >
                        track
                    </Link>{" "}
                    customer behavior in your online store and may be updated differently from report to report. For
                    example, "Conversion rate over time" is up to date with the current time but may vary over 48 hours
                    as Shopify tests for bot traffic. "Product recommendations with low engagement," however, includes
                    data from the past 30 days and may not include the most recent 24 hours.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Customer reports </strong>
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/customers-reports"
                        target="new"
                    >
                        provide
                    </Link>{" "}
                    valuable insights into average order count, average order totals, and more, with reports including
                    "New customers over time," "New vs returning customers," and "Customers by location." Note that many
                    of these reports will not show data from the previous 12 hours. Additionally, the
                    documentation&nbsp;
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/customers-reports"
                        target="new"
                    >
                        states
                    </Link>{" "}
                    that new customer data will reflect their entire order history, even if it extends beyond the
                    selected timeline.
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-28/16428495-7d99-4330-9e50-384f14b4bc21/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1404,825&force_format=jpeg&q=100&width=1120.0"
                    alt="New customers over time"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Finance reports </strong>
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/finances-report"
                        target="new"
                    >
                        give
                    </Link>{" "}
                    financial information concerning your store and do not include your Shopify billing information.
                    These reports, including "Finance summary," "Gross profit breakdown," and "Total sales by order,"
                    are up to date within about a minute.
                </ListItem>
                <Alert status="warning" mb={"spacer-6"}>
                    <AlertIcon />
                    <Text>
                        There may be differences between your "Sales" and "Payments" reports for two reasons, according
                        to the{" "}
                        <Link
                            textDecoration="underline"
                            to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/finances-report"
                            target="new"
                        >
                            documentation
                        </Link>
                        . First, sales refer to orders placed, while payments refers to actual payments customers have
                        made. Second, the timelines could be different, as an order could be placed one month and paid
                        for the next month.
                    </Text>
                </Alert>
                <ListItem pb={"spacer-4"}>
                    <strong>Fraud reports </strong>
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/fraud-reports"
                        target="new"
                    >
                        refer
                    </Link>{" "}
                    to information about orders that are or might be fraudulent. These cannot be customized at any
                    Shopify tier. The reports include "Acceptance rate," "High risk orders rate," and "Canceled due to
                    fraud."
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Inventory reports </strong>
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/inventory-reports"
                        target="new"
                    >
                        display
                    </Link>{" "}
                    how much inventory is being sold each day with reports like "Month-end inventory value" and
                    "Inventory sold daily by product." Shopify{" "}
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/inventory-reports"
                        target="new"
                    >
                        notes
                    </Link>{" "}
                    that any product or variant deletions will still show up in the reports for historical purposes, and
                    that it can take up to 72 hours for a change to appear in the reports.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Marketing reports </strong>
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/marketing-reports"
                        target="new"
                    >
                        demonstrate
                    </Link>{" "}
                    how effective your marketing campaigns are at creating conversions and collect data from all online
                    Store Channel orders. Reports include "Attribution model comparison" and "Conversion by first
                    interaction."
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Order reports </strong>
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/order-reports"
                        target="new"
                    >
                        assess
                    </Link>{" "}
                    order counts, fulfillment, shipping, and more with reports such as "Items returned over time" and
                    Shipping labels by order."
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-28/853b6bb2-fd06-467e-8b36-d6b3c9d7cc98/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1346,850&force_format=jpeg&q=100&width=1120.0"
                    alt="Items returned over time"
                />
                <ListItem pb={"spacer-4"}>
                    <strong>Profit margin reports </strong>
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/profit-reports"
                        target="new"
                    >
                        trace
                    </Link>{" "}
                    margins and profit. According to the{" "}
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/profit-reports"
                        target="new"
                    >
                        documentation
                    </Link>
                    , your products and their variants must have a cost per item to be included in reports such as
                    "Gross profit by product" and "Gross profit by product variant." Shopify also notes that discounts
                    and refunds can affect your profit margin and should be considered accordingly.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Retail sales reports</strong> are only available if you have in-person sales and&nbsp;
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/retail-sales-reports"
                        target="new"
                    >
                        contain
                    </Link>{" "}
                    information about your customers' point of sale orders. This data is up to date within about a
                    minute with reports such as "POS staff daily sales total" and "POS total sales by product."
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Sales reports </strong>
                    <Link
                        textDecoration="underline"
                        to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/report-types/default-reports/sales-report"
                        target="new"
                    >
                        detail
                    </Link>{" "}
                    customer orders by sales over time, by product, by channel, and more. Most of these reports are up
                    to date within about a minute, but it can take 12-72 hours for the "Sales by discount" report to
                    update. Additional reports include "Total sales by vendor," "Total sales by billing location," and
                    "Average order value over time."
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                While many of these reports are straightforward, some will give you access to additional dashboards,
                including the Web Performance report, which displays your site's Core Web Vitals using real-user CrUX
                data. The Web Performance dashboard presents information about:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Loading Speed, or Largest Contentful Paint</ListItem>
                <ListItem pb={"spacer-4"}>Interactivity, or Interaction to Next Paint</ListItem>
                <ListItem pb={"spacer-6"}>Visual Stability, or Cumulative Layout Shift</ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                While this data is also available in your store's CrUX Report, investigating one metric at a time can
                help you understand how a change in your store might have affected your performance or users.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-06-17/92854c27-0469-4121-ae67-8a04c1ef1494/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1325,615&force_format=png&width=1120.0"
                alt="Web performance dashboard"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Shopify's documentation{" "}
                <Link
                    textDecoration="underline"
                    to="https://help.shopify.com/en/manual/online-store/web-performance/web-performance-dashboard"
                    target="new"
                >
                    notes
                </Link>
                &nbsp;that{" "}
                <strong>
                    any store's online performance can be affected by additions such as apps, analytics tracking pixels,
                    media size and quality, third-party code, and even your theme code.
                </strong>{" "}
                Because of that possibility, the individual Web Performance reports display additional information and
                markers to highlight changes.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The example below shows a Loading Speed report, which highlights Largest Contentful Paint measurements
                in more detail over time. You can view the metric by different devices or timeframes, and you can hover
                over the event markers that indicate when a new app was installed, a theme was edited, etc.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-06-17/e323b1b3-7165-4089-8426-3e2535906fb0/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1328,863&force_format=png&width=1120.0"
                alt="Loading Speed report"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Learn more about the metrics this Shopify report represents by visiting the{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_THIRD_PARTY_CRUX}>
                    CRuX 101 documentation
                </Link>
                , and explore the default reports available to find the data you need to optimize your store and
                customer experience even further.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Live View
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Shopify offers a Live View option under Analytics in the main menu so you can see what is happening in
                your store in real time. The documentation{" "}
                <Link
                    textDecoration="underline"
                    to="https://help.shopify.com/en/manual/reports-and-analytics/shopify-reports/live-view"
                    target="new"
                >
                    states
                </Link>
                &nbsp;this is especially helpful for tracking progress during big sales or holidays.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The information will be displayed in your store's local time. If your time zone does not match what you
                would expect, you can update it by visiting your admin settings.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-06-17/65a40b38-6532-44c9-a165-c0de5c77cba2/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2156,1280&force_format=png&width=1120.0"
                alt="Live View"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can see the following metrics in the live view. Any times mentioned are from the store's local time
                zone.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Visitors right now,</strong> referring to visitors who have been active within the past 5
                    minutes
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Total sales</strong> "based on the orders on your online store and from other sales channels
                    since midnight"
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Total sessions</strong> in your online store since 12 am
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Total orders</strong> from all sales channels since midnight
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Top locations</strong> where your customers are located since midnight
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Customers, </strong>showing a count of first-time customers versus returning customers
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Top products,</strong> meaning the products that contributed most to total sales for your
                    online store since midnight
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Customer behavior</strong> tracks the online store sessions in the last 10 minutes that have
                    added items to the cart, reached checkout, or purchased a product
                </ListItem>
            </UnorderedList>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="session-counts">
                Note On Session Counts With {BRAND_NAME}
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You may notice that your number of sessions, as reported by Shopify, reduces when you turn on{" "}
                {BRAND_NAME}. This is because{" "}
                <strong>
                    {BRAND_NAME} is helping filter out a bug where Shopify is counting any bot producing RUM data as a
                    real human,
                </strong>{" "}
                meaning these RUM bots are counted as visitors and sessions.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Your visitor and session counts will both be reduced and corrected to the real human number if you turn
                on {BRAND_NAME}, and will return to the higher numbers if you turn off {BRAND_NAME}. This does not
                change any external reporting if your other Shopify reports are correct because this bot data does not
                reflect human customers.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                While {BRAND_NAME} has not been informed of a specific timeline, we assume this will be temporary as
                Shopify will likely fix the bug at some point.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="shopify-nostra">
                Shopify Metrics + {BRAND_NAME}
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} contributes to your store optimizations to improve your Shopify metrics in several ways.
                For a full breakdown of the expected impact of {BRAND_NAME}'s {MAIN_PRODUCT}, visit our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_METRICS_ROI}>
                    ROI Metrics documentation
                </Link>
                . Here are some of the highlights:
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    As with any metrics related to {BRAND_NAME}, keep in mind our products are optimization tools. If
                    your website updates its third-party applications, product images and slideshows, tracking pixels,
                    etc., those changes could impact your scores unrelated to your performance gains with {BRAND_NAME}.
                </Text>
            </Alert>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Enhance your store's baseline.</strong> {BRAND_NAME}'s {MAIN_PRODUCT} is especially known
                    for its incredible reduction of TTFB, often resulting in a 5-10x reduction in the time it takes to
                    begin downloading your content. That huge latency decrease benefits every other part of your user
                    experience and site speed!
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Boost your overall metric scores. </strong>Prioritizing speed has proven to lead to&nbsp;
                    <Link
                        textDecoration="underline"
                        to={"https://www.conductor.com/academy/page-speed-resources/faq/amazon-page-speed-study/"}
                        target="new"
                    >
                        more revenue
                    </Link>
                    ,{" "}
                    <Link
                        textDecoration="underline"
                        to={
                            "https://www.thinkwithgoogle.com/marketing-strategies/app-and-mobile/mobile-page-speed-new-industry-benchmarks/"
                        }
                        target="new"
                    >
                        fewer bounces
                    </Link>
                    ,{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://www.shopify.com/blog/site-speed-importance"}
                        target="new"
                    >
                        better conversion rates
                    </Link>
                    ,{" "}
                    <Link
                        textDecoration="underline"
                        to={"https://www.google.com/search/howsearchworks/how-search-works/ranking-results/"}
                        target="new"
                    >
                        improved search engine page rankings
                    </Link>
                    ,&nbsp;
                    <Link
                        textDecoration="underline"
                        to={"https://www.facebook.com/business/help/423781975167984"}
                        target="new"
                    >
                        lower advertising costs along with higher ad quality
                    </Link>
                    , and more. {BRAND_NAME}'s {MAIN_PRODUCT} speeds up your content to do just that.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Get huge performance gains across the board.</strong>
                    &nbsp;{BRAND_NAME}'s Shopify-based customers have seen overall performance improvements of 20-30%.
                    We have seen customers increase CVR by 8%, decrease load time by 23%, and increase revenue per user
                    by 4.65% with {BRAND_NAME}. You don't have to take our word for it. Check out some of our{" "}
                    <Link textDecoration="underline" to="https://www.nostra.ai/resources#case-studies" target="new">
                        recent case studies
                    </Link>
                    .
                </ListItem>
            </UnorderedList>
            <HStack mb={"spacer-6"} backgroundColor={"blue.100"} p={"spacer-6"} alignItems={"center"}>
                <ChatIcon boxSize={6} />
                <Stack pl={"spacer-4"}>
                    <Text textStyle={"text-header-S"} mb={"spacer-2"}>
                        {" "}
                        When we started using {BRAND_NAME}, our site speed increased overnight. After that, our{" "}
                        <Link
                            textDecoration="underline"
                            to="https://www.nostra.ai/success-stories/how-rifruf-increased-its-conversion-rate-by-eight-percent"
                            target="new"
                        >
                            conversions immediately lifted
                        </Link>
                        , and our bounce rates improved as well.
                    </Text>
                    <Text textStyle={"text-body-regular"}>—Peter Liu, Co-Founder and CEO at RIFRUF</Text>
                </Stack>
            </HStack>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Not sure how {BRAND_NAME} delivers these benefits? Visit our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_EDGE_DELIVERY_ENGINE}>
                    {MAIN_PRODUCT} documentation,
                </Link>{" "}
                or{" "}
                <Link
                    textDecoration="underline"
                    to="https://nostra-corporate.webflow.io/contact-nostra-support"
                    target="new"
                >
                    contact support
                </Link>
                &nbsp;with additional questions.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
