import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const SpeedReportsDoc = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Reports"}
                menuItem1Link={ROUTES.DOCUMENTATION_REPORTS}
                menuItem2={"Speed Reports"}
            />
            <Header pb={"spacer-8"}>Speed Reports</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s speed reports include the most fundamental information about the effect of the{" "}
                {MAIN_PRODUCT} on your site's performance. The included metrics were selected based on best practices
                for site performance improvements, incorporating industry standards from Google and other major platform
                providers alongside {BRAND_NAME}'s own speed experts.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The speed reports transform real user metrics (RUM) from {BRAND_NAME}'s reporting into intuitive
                visualizations to help you recognize every step to success in your load times—and your optimizations
                with {BRAND_NAME}.
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Key Metrics
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The time it takes for an entire page (or document) to load results from a series of progressive steps,
                from a user's browser requesting and receiving relevant content from a host server to processing
                JavaScript, CSS, and other files to rendering the user interface.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Naturally, the faster each step is along the way, the better your total load time will be. {BRAND_NAME}
                's executive summary speed report reflects the cumulative effect our {MAIN_PRODUCT} has across your
                site, providing a direct comparison between your site with and without {BRAND_NAME} enabled.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-11-04/071b90c4-b427-452b-9db1-8cd0187a663a/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1314,652&force_format=jpeg&q=100&width=1120.0"
                alt="Speed report"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The core speed metrics we highlight in this centralized report include the following web vitals, all
                measured in milliseconds.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Time-To-First-Byte (TTFB)</strong> measures web server responsiveness by tracking the time a
                    server takes to respond to a client's request and deliver the first byte to the user. This timing
                    includes redirects, the DNS lookup, and more.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>First Contentful Paint (FCP) </strong>tracks the time it takes for the first bit of content
                    to become visible to a user, which occurs before your Largest Contentful Paint (LCP). This time is
                    important to consider as it marks the first moment your visitors perceive the page response.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://www.nostra.ai/blogs-collection/largest-contentful-paint-lcp"
                            target="new"
                        >
                            Largest Contentful Paint (LCP)
                        </Link>
                    </strong>{" "}
                    indicates when the largest element on a given page (usually a photo or video in the eCommerce world)
                    becomes visible to your customers. LCP measures the time it takes that element to render and begins
                    when the page first begins to load.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Visually Ready</strong> indicates when the <i>visible area</i> of the page has completed
                    rendering and is presented to the user as complete or ready in regards to functional interaction.
                    This is not a primary Core Web Vitals metric; however, we believe it can be helpful to consider.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Total load </strong> documents the time it takes for a page to begin loading to when a page
                    finishes loading, including all scripts, stylesheets, and media elements—with the exception of
                    anything lazily loaded.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Additionally, you will see a few metrics at the top of your overarching speed visualization that provide
                context regarding your coverage with {BRAND_NAME}. Visit the{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_REPORTS} target="new">
                    Reports Overview
                </Link>{" "}
                to get more information on how {BRAND_NAME} collects this data.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>"Total sessions" </strong>refers to the number of human sessions across your store during
                    the selected date range.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>"Sessions with {BRAND_NAME}" </strong>relays the total number of sessions served by{" "}
                    {BRAND_NAME}'s {MAIN_PRODUCT}.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>"Without" </strong>displays the total number of sessions served via your original store's
                    processes.
                </ListItem>
            </UnorderedList>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    Unlike your store's CrUX data,{" "}
                    <strong>these {BRAND_NAME}-specific metrics are updated every day.</strong> Keep this in mind as you
                    assess your speed reports over time or change the date range displayed.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Interpreting The Reports
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                With {BRAND_NAME}'s overarching speed report, it's easy to see the advantages of using the{" "}
                {MAIN_PRODUCT}. Regardless of the metric, if the {BRAND_NAME} bars on the chart in yellow are shorter
                with fewer milliseconds than the bars of your site without {BRAND_NAME} in blue, it means your site is
                faster for that metric with {BRAND_NAME} enabled.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Each metric is measured in milliseconds along the same scale, making it simple to compare the
                differences in load time across your store. <strong>The lower the scores, the faster the site.</strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                All the core speed metrics have two additional reports attached to them so you can further investigate
                their loading impacts. You can find these in-depth visualizations under the executive summary report.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>The top pages by sessions for the metric. </strong> These graphs display the relative speed
                    improvements—meaning the percentage difference between your results with and without {BRAND_NAME}
                    &nbsp;enabled—of your most visited pages.
                </ListItem>
                <Alert status="warning" mb={"spacer-6"}>
                    <AlertIcon />
                    <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                        <strong>
                            If you do not see a {BRAND_NAME} comparison for a top page by sessions, there were no
                            sessions served by the {MAIN_PRODUCT} for that page in the selected date range.
                        </strong>{" "}
                        This can occur because our analytics system tracks all store sessions, not just those with a{" "}
                        {BRAND_NAME} comparison.
                    </Text>
                </Alert>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-16/18bab954-176b-4408-b67c-82c961863290/user_cropped_screenshot.jpeg?tl_px=12,0&br_px=1158,614&force_format=png&width=1120.0"
                    alt="top pages by sessions graph"
                ></DocumentationImage>
                <ListItem pb={"spacer-6"}>
                    <strong>The top pages that have benefited from {BRAND_NAME}. </strong> These graphs include the
                    pages with the highest relative speed improvements between your original store and your store with{" "}
                    {BRAND_NAME} enabled. Again, relative changes capture the percentage change in speed beginning with
                    the greatest percentage improvement and going down from there.
                </ListItem>
                <DocumentationImage
                    src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-16/6a5f41c3-bb86-4b85-8e2e-b72315769186/user_cropped_screenshot.jpeg?tl_px=5,0&br_px=1152,612&force_format=png&width=1120.0"
                    alt="top pages by speed benefit graph"
                ></DocumentationImage>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Your store's human visitors will have a range of experiences on your site because of uncontrollable
                factors such as the browser they use, the strength of their internet connection, and their geographic
                location.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Due to this variability, there are a few additional factors to keep in mind as you read your speed
                reports to assist you in drawing informed insights from the metrics.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>The data is gathered from your site's origin,</strong> meaning the top-level values are an
                    aggregation based on all your pages.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>The speed reports reflect every device type, </strong>meaning you will see an aggregation of
                    phone, tablet, and desktop experiences.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>{BRAND_NAME}'s speed reports rely on the 75th percentile,</strong> or P75, in alignment with
                    industry standards. The 75th percentile is{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_REPORTS_PERCENTILES} target="new">
                        commonly used
                    </Link>
                    , as it delivers a majority user experience while minimizing outliers to prevent significant skews
                    in the data.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Remember, metrics across the eCommerce space are highly variable and can be impacted by any changes to
                your site, including adding a new application or A/B test, updating hero images, or removing a
                third-party script. If you see any noticeable shifts in your data, consider what may have been added to,
                removed from, or generally changed on your site that could have affected your user experience beyond{" "}
                {BRAND_NAME}'s optimizations.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
