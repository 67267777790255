import { PageContainer } from "../../PageContainer";
import { Text, UnorderedList, ListItem, Box, Code } from "@chakra-ui/react";
import { Header, Link } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const InitialTips = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Getting Started"}
                menuItem1Link={ROUTES.DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL}
                menuItem2={"Initial Tips"}
            />
            <Header pb={"spacer-8"}>Initial Tips</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Congratulations on launching {BRAND_NAME} on your site! Now that you have enabled our {MAIN_PRODUCT}, we
                want to ensure you are familiar with the following resources and tips to keep everything running
                smoothly:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <b>Contact support when making changes that might affect personalized content,</b> such as changing
                    a shopping cart, switching a theme, or adding an app that adds personalization. Our engineering team
                    will then evaluate your changes and make any necessary updates to maintain your site's optimal
                    performance and functionality. Otherwise, you should be able to preview themes as usual.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <b>Clear your cache manually.</b> For small content changes that you would like to see live on your
                    site, you can manually clear your cache. In the portal menu, select&nbsp;
                    <b>Stores</b> and click on the first icon under "Actions" that looks like a tiered bucket with an
                    "x" in front of it. You will be taken to a "Clear Cache Schedule" page with options to clear your
                    cache immediately or schedule a cache clearing for later. Learn more by visiting the{" "}
                    <Link textDecoration="underline" to={`${ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}`}>
                        When And Why To Clear Your Cache user guide
                    </Link>
                    .
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <b>Compare your site with and without {BRAND_NAME}.</b> If you need to compare your site with and
                    without&nbsp;
                    {BRAND_NAME} enabled for any reason, you can add <Code>?skipCache</Code> to your URL in the browser
                    to see what your origin server (Shopify or Salesforce Commerce Cloud) would have served you. Learn
                    more by visiting the{" "}
                    <Link textDecoration="underline" to={`${ROUTES.DOCUMENTATION_TEST_YOUR_SITE_WITHOUT_NOSTRA}`}>
                        Troubleshoot Your Site user guide
                    </Link>
                    .
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <b>Perform additional debugging.</b> If you need to troubleshoot any potential issues,&nbsp;
                    <Link
                        textDecoration="underline"
                        to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                        target="new"
                    >
                        contact support
                    </Link>
                    &nbsp;or select the "Edit Store" icon on the <b>Stores</b> page to temporarily disable {BRAND_NAME}.
                    If you share the issue with us by filing a help ticket, our team will be happy to help you debug.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <b>Get more guidance by exploring {BRAND_NAME}'s documentation.</b> You will find helpful tips and
                    information related to both {BRAND_NAME} and technical areas concerning eCommerce.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <Link
                    textDecoration="underline"
                    to={"https://nostra-corporate.webflow.io/contact-nostra-support"}
                    target="new"
                >
                    Contact support
                </Link>{" "}
                if you have any questions or concerns about your website with {BRAND_NAME}. We would also love to hear
                your ideas on how we can improve our offerings. Fill out the support form and let us know what you
                think.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
