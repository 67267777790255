import { PageContainer } from "../../PageContainer";
import { Text, Box, Code } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, SECONDARY_PRODUCT } from "../../../config/BRAND_NAME";

export const CrawlerOptimizationMetrics = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Metrics"}
                menuItem1Link={ROUTES.DOCUMENTATION_METRICS_ROI}
                menuItem2={`${SECONDARY_PRODUCT} Metrics`}
            />
            <Header pb={"spacer-8"}>{SECONDARY_PRODUCT} Metrics</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME}'s {SECONDARY_PRODUCT} targets the bot traffic from our eCommerce Edge Delivery Platform's
                advanced bot detection process and reroutes it to a highly optimized version of your store, prepared
                explicitly for bot standards.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This strategic allocation leads to consistently high bot scores, as measured by Google and other major
                search engines, and enhanced visibility on search engines. These benefits, in turn, make your content
                more accessible to your target human audience as they browse the web.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Learn more about the metrics you should consider concerning our {SECONDARY_PRODUCT} product below.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Faster Delivery
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If your store has {SECONDARY_PRODUCT} enabled, you will be able to see the difference in your
                bot-optimized site immediately because bots will receive a dedicated version of your site—the statically
                rendered page—every time a bot crawls your store.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                From the Network tab of Chrome's DevTools, you will be able to see how much faster the page load for a
                bot is on your site, assuming your store has {BRAND_NAME}'s {SECONDARY_PRODUCT} enabled.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                To see the {SECONDARY_PRODUCT} delivery speed in action, load the URL you would like to test with{" "}
                {BRAND_NAME}'s <Code>forceSeo</Code>
                &nbsp;parameter attached to it: <Code>www.example.com?forceSeo</Code>. Run that same URL in a new tab
                while looking at the DevTools Network tab but with the added parameter <Code>skipCache</Code> to see the
                bot delivery on your original site: <Code>www.example.com?forceSeo&skipCache</Code>.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Both tests will show you what a bot would experience while loading the page in question. However, the
                first test will return the metrics with {SECONDARY_PRODUCT} enabled, while the second will return the
                metrics from your original site.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For example, this is the load time for bots from a real customer's site{" "}
                <strong>without {SECONDARY_PRODUCT}</strong>:{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-26/17c9b367-856e-4b2e-b9fb-63abb547bed8/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=686,24&force_format=png&width=688"
                alt="Load stats without bot optimization"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                This is the same page for bots optimized <strong>with {SECONDARY_PRODUCT}</strong>:{" "}
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-26/3a330e6c-3524-40be-8f61-3fb662b58861/screenshot.jpeg?tl_px=0,0&br_px=688,27&force_format=png"
                alt="Load stats with bot optimization"
                loading="eager"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Search Engine Crawlers Can Skip The Rendering Queue
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Without {BRAND_NAME}, your website undergoes additional processing steps by search engine crawlers due
                to the presence of JavaScript. This includes queuing for rendering, execution of JavaScript, and
                re-processing of the rendered HTML. These extra steps consume more resources and time, and may lead to
                potential indexing issues, as not all search engine bots handle JavaScript well.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                With {BRAND_NAME}, your pages are prerendered and cached, simplifying the crawling process for search
                engine bots. This eliminates the need for additional rendering queues and ensures faster indexing
                without compromising on the site's functionality. {BRAND_NAME}'s prerendering approach caters to a wide
                range of search engine bots, including those with limited JavaScript support, ensuring global
                accessibility without issues. In short, bots like Google Bot will require far fewer resources to process
                your site.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-04-26/3c1a30d8-3bbe-4bbe-a320-fcfc8a334b54/screenshot.jpeg?tl_px=0,38&br_px=1200,1961&force_format=png&width=672"
                alt="Crawler Optimization graphic"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For a deeper look at this process, visit the{" "}
                <Link
                    textDecoration="underline"
                    target="new"
                    to={"https://www.nostra.ai/blogs-collection/myth-crawled-version-of-your-site-is-gaming-the-system"}
                >
                    related post on {BRAND_NAME}'s blog
                </Link>
                .
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                New Content Will Appear In The Search Index Faster
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Thanks to your site's optimized bot crawling, new products and blog pages, as well as rapidly-changing
                content including campaigns, price changes, sales, and news, will show up in user searches sooner.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Crawl Budget Gains
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Large sites of at least 100,000 pages will see their crawler budget gradually increasing, especially if
                they were previously running out of crawler budget.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                More Frequent Crawling
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google will crawl your site more often, even when it comes to smaller sites, as long as they have enough
                traffic. You can find information about your site's crawling experience by using{" "}
                <Link
                    textDecoration="underline"
                    to={"https://support.google.com/webmasters/answer/9012289?hl=en"}
                    target="new"
                >
                    Google's URL Inspection Tool
                </Link>
                .{" "}
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Remove JavaScript Errors
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Errors caused by JavaScript will disappear. This is because our {SECONDARY_PRODUCT} product strips out
                any JavaScript that bots do not need and struggle to process.{" "}
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Access International Search Engines
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google{" "}
                <Link
                    textDecoration="underline"
                    to={"https://developers.google.com/search/docs/crawling-indexing/javascript/javascript-seo-basics"}
                    target="new"
                >
                    notes
                </Link>{" "}
                that not all bots have the same capabilities, especially when it comes to JavaScript. Beyond Bing,
                Yahoo!, and DuckDuckGo, global companies know Yandex in Russia, Asia and Europe, Baidu in China, Naver
                in South Korea, Yahoo! Japan in Japan, Yahoo! Taiwan in Taiwan, Seznam in Czech Republic, and Qwant in
                France, are highly popular search engines.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                With all these different search engine crawlers with a range of capabilities and limitations,
                prerendering your site is the most reliable way to know it can be crawled globally without issues, even
                by bots that don't support Javascript.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you have requested that your site be crawled by one or many of these international search engines,
                you will show up where you might not have before due to limitations of JavaScript processing.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                For a more in-depth description of how this process works, visit our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CRAWLER_OPTIMIZATION}>
                    {SECONDARY_PRODUCT} documentation
                </Link>
                .
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
