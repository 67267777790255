import { Text, Box, Avatar, Flex, Badge, HStack, Divider, useMediaQuery } from "@chakra-ui/react";
import { CSSProperties } from "react";
import { Link } from "../../components";
import { EcomStoreApp, SingleShopifyApp } from "../../types";
import { truncate } from "../../lib";
import { ROUTES } from "../../Routes";
import { generatePath } from "react-router-dom";

export const ListApp = ({ app, style }: { app: SingleShopifyApp; style: CSSProperties }) => {
    const [isMobile] = useMediaQuery("(max-width: 744px)");
    return (
        <Flex
            style={style}
            key={app.id}
            padding={"spacer-2"}
            border={"1px"}
            borderColor={"gray.200"}
            borderRadius={"0.75rem"}
            width={"100%"}
            overflow={"auto"}
        >
            <Link to={generatePath(ROUTES.SHOPIFY_APP, { appId: app.id })}>
                <Avatar name={app.title} src={app.iconUrl} />
            </Link>
            <Box ml="3">
                <Link to={generatePath(ROUTES.SHOPIFY_APP, { appId: app.id })}>
                    <Text fontWeight="bold">{app.title}{" "}
                        {app.appType === 'app' ? (
                            <Badge>App</Badge>
                        ) : (
                            <Badge>Sales Channel</Badge>
                        )}
                    </Text>
                    <Text fontWeight="bold">
                        {app.supported === true ? (
                            <Badge colorScheme="blue">Supported</Badge>
                        ) : (
                            <Badge colorScheme="red">Unsupported</Badge>
                        )}
                    </Text>
                </Link>
                <Text ml="spacer-1">
                    {app.description ? truncateDescription(app.description, isMobile) : "No description provided."}
                </Text>
                <HStack height={{ base: "30%", lg: "15px" }} width="100%" mt="spacer-2">
                    <Text
                        textStyle={{
                            base: "text-body-meta-bold",
                            md: "text-body-meta-bold",
                        }}
                        ml="spacer-1"
                    >
                        Active Customers: {countStoresWithApp(app.EcomStoreApp)}
                    </Text>
                    <Divider orientation={"vertical"} />
                    <Text
                        textStyle={{
                            base: "text-body-meta-bold",
                            md: "text-body-meta-bold",
                        }}
                        ml="spacer-1"
                    >
                        Inactive Customers: {countStoresWithAppDeleted(app.EcomStoreApp)}
                    </Text>
                    <Divider orientation={"vertical"} />
                    <Text
                        textStyle={{
                            base: "text-body-meta-bold",
                            md: "text-body-meta-bold",
                        }}
                        ml="spacer-1"
                    >
                        Total Installed: {totalInstalledAppCount(app.EcomStoreApp)}
                    </Text>
                </HStack>
            </Box>
        </Flex>
    );
};

const countStoresWithApp = (ecomStoreApp: EcomStoreApp[]) => {
    let activeCount = 0;
    ecomStoreApp.forEach((store) => {
        if (!store.deleted) {
            activeCount++;
        }
    });
    return activeCount;
};

const countStoresWithAppDeleted = (ecomStoreApp: EcomStoreApp[]) => {
    let inactiveCount = 0;
    ecomStoreApp.forEach((store) => {
        if (store.deleted) {
            inactiveCount++;
        }
    });
    return inactiveCount;
};

const totalInstalledAppCount = (ecomStoreApp: EcomStoreApp[]) => {
    return ecomStoreApp.length;
};

const truncateDescription = (description: string, isMobile: boolean) => {
    if (description.length > 100) {
        return truncate(description, 65);
    }
    if (isMobile) {
        return truncate(description, 25);
    }
    return description;
};
