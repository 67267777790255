import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, ListIcon, List } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { useAuthenticatedUser } from "../../../hooks";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const TourTheNostraPortal = () => {
    const { isAdminRole, isCloudOpsRole } = useAuthenticatedUser();

    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Getting Started"}
                menuItem1Link={ROUTES.DOCUMENTATION_TOUR_THE_NOSTRA_PORTAL}
                menuItem2={`Tour The ${BRAND_NAME} Portal`}
            />
            <Header pb={"spacer-8"}>Tour The {BRAND_NAME} Portal</Header>
            {isAdminRole ? (
                <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                    The {BRAND_NAME} portal is straightforward and easy to use. Several options are available to you
                    through the main menu: <strong>Home</strong>, <strong>Stores</strong>,{" "}
                    <strong>Nostra Status</strong>, <strong>Documentation</strong>, <strong>Troubleshoot</strong>, and{" "}
                    <strong>Contact Support</strong>.
                </Text>
            ) : (
                <>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        The {BRAND_NAME} portal is straightforward and easy to use. Several options are available to you
                        through the main menu: <strong>Home</strong>, <strong>Stores</strong>,{" "}
                        <strong>Nostra Status</strong>
                        ,&nbsp;
                        {isCloudOpsRole ? <strong>Organizations</strong> : <strong>Organization</strong>},{" "}
                        <strong>Documentation</strong>, <strong>Troubleshoot</strong>, and{" "}
                        <strong>Contact Support</strong>.
                    </Text>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        In a mobile view, store owners can additionally access <strong>Manage Users</strong> through the
                        main menu. That page can be found in a desktop view by clicking on your profile icon. Learn more
                        about managing users as store owners by visiting the&nbsp;
                        <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_MANAGE_USERS}>
                            Manage Users resource
                        </Link>
                        .
                    </Text>
                </>
            )}
            <DocumentationImage
                src={
                    isAdminRole
                        ? "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-08/2c81b379-37dc-48dd-9cbd-fd6008e8ac2f/screenshot.jpeg?tl_px=0,0&br_px=1533,665&force_format=png&width=1120.0"
                        : "https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-08/db9fe3dc-c01e-44a8-b46d-37c692caea8c/screenshot.jpeg?tl_px=0,0&br_px=1535,655&force_format=png&width=1120.0"
                }
                alt="Home page"
                loading="eager"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Home
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You will find yourself on the <strong>Home</strong> page when you first log in. Here, you will see an
                overview of {BRAND_NAME}'s core products along with any relevant information you should know, such as
                holiday hours or third-party technology updates that may affect your site. If you have any stores that
                currently have {BRAND_NAME} turned off, you will see them listed at the bottom of the page.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Stores
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-4"}>
                The <strong>Stores</strong> section is where you can access your {BRAND_NAME} tools and resources:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Clear Cache </ListItem>
                <ListItem pb={"spacer-4"}>Edit Store</ListItem>
                <ListItem pb={isCloudOpsRole ? "spacer-4" : "spacer-6"}>Reports</ListItem>
                {isCloudOpsRole ? <ListItem pb={"spacer-4"}>Manage Experiments</ListItem> : null}
                {isCloudOpsRole ? <ListItem pb={"spacer-6"}>Manage Organization</ListItem> : null}
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The "Status" column will indicate the current state of your store as DNS Setup, Testing, Enabled,
                Disabled, or Disabled By Provider. DNS Setup means you need to update your DNS, or Domain Name System,
                to allow {BRAND_NAME} to serve your optimized site content. Testing means {BRAND_NAME}'s engineers are
                currently configuring your store to have great results with {BRAND_NAME}. Enabled and Disabled simply
                mean {BRAND_NAME} is on or off.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Disabled By Provider means {BRAND_NAME} or a qualified service partner has turned off {BRAND_NAME}'s
                services for your site. The provider will ensure {BRAND_NAME} remains off until they have verified that{" "}
                {BRAND_NAME} is ready to be re-enabled on your site. Learn more about when this could occur by visiting
                the&nbsp;
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_TURN_OFF_ON}>
                    Turn {BRAND_NAME} On/Off resource
                </Link>
                .
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-08/94a082ed-1bab-4002-bb2e-73bbb87acdbd/File.jpeg?tl_px=0,0&br_px=1294,427&force_format=png&width=1120.0"
                alt="Store page"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                {BRAND_NAME} Status
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The {BRAND_NAME} Status page is where you can view the current status of {BRAND_NAME} services,
                including uptime, downtime, and any scheduled maintenance.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-08-08/de4b79ab-93d5-4bab-9dee-d521459e6259/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1207,814&force_format=png&width=1120.0"
                alt="Status page"
            />
            {!isAdminRole ? (
                <>
                    <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                        Organization
                    </SubHeader>
                    <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                        The <strong>Organization</strong> section provides store owners{" "}
                        {isCloudOpsRole ? "and CloudOps users " : null}
                        with easy access to manage users and any stores an organization has registered with {BRAND_NAME}
                        . Learn more by visiting the{" "}
                        <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_MANAGE_ORGANIZATION}>
                            Manage Organization resource
                        </Link>
                        .
                    </Text>
                </>
            ) : null}
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Documentation
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can visit {BRAND_NAME}'s <strong>Documentation</strong> through the main menu. If you need guidance
                in getting started with {BRAND_NAME}, want to learn more about our core products, or would benefit from
                user guides answering frequently asked questions, our official documentation is the first place to
                check.{" "}
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Troubleshoot
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The <strong>Troubleshoot</strong> page is a quick reference guide that provides tips and resources to
                help you troubleshoot any issues you may encounter, regardless of when they arise.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Contact Support
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Request additional help by clicking on <strong>Contact Support</strong> in the main menu. You will be
                directed to a {BRAND_NAME} contact page, where you can submit a help request to our Customer Success
                team.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Get To Know Your {BRAND_NAME} Resources
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The following resources are accessible through the portal menu or through the sections briefly described
                in this tour. Learn more by visiting the resource guides if applicable below:{" "}
            </Text>
            <List textStyle={"text-body-regular"} pl={"spacer-6"} color="blue.600">
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CLEAR_CACHE} target="new">
                    <ListItem pb={"spacer-4"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Clear Cache
                    </ListItem>
                </Link>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_REPORTS} target="new">
                    <ListItem pb={"spacer-4"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Reports
                    </ListItem>
                </Link>
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_TURN_OFF_ON} target="new">
                    <ListItem pb={"spacer-4"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Turn {BRAND_NAME} On/Off
                    </ListItem>
                </Link>
                {isCloudOpsRole ? (
                    <ListItem pb={"spacer-4"} color={"text-link-primary-default"}>
                        <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Manage Experiments
                    </ListItem>
                ) : null}
                {!isAdminRole ? (
                    <>
                        <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_MANAGE_USERS} target="new">
                            <ListItem pb={"spacer-4"}>
                                <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Manage Users
                            </ListItem>
                        </Link>
                        <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_MANAGE_ORGANIZATION} target="new">
                            <ListItem pb={isCloudOpsRole ? "spacer-4" : "spacer-6"}>
                                <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Manage Organization
                            </ListItem>
                        </Link>
                        {isCloudOpsRole ? (
                            <Link
                                textDecoration="underline"
                                to={ROUTES.DOCUMENTATION_MANAGE_ALL_ORGANIZATIONS}
                                target="new"
                            >
                                <ListItem pb={"spacer-6"}>
                                    <ListIcon as={ChevronRightIcon} boxSize={5}></ListIcon>Manage All Organizations
                                </ListItem>
                            </Link>
                        ) : null}
                    </>
                ) : null}
            </List>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you have additional questions about how to use the {BRAND_NAME} portal or any of our products,
                explore our <strong>Documentation</strong>.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
