import { PageContainer } from "../../PageContainer";
import { Text, Box, Alert, AlertIcon, Stack, HStack, UnorderedList, ListItem } from "@chakra-ui/react";
import { Header, SubHeader, Link } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";
import { ChatIcon } from "@chakra-ui/icons";

export const WebVitalsMetrics = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Metrics"}
                menuItem1Link={ROUTES.DOCUMENTATION_METRICS_ROI}
                menuItem2={`Web Vitals`}
            />
            <Header pb={"spacer-8"}>Web Vitals</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Website performance is critical for online success, with page speed metrics serving as key indicators of
                user experience quality. These metrics are categorized into Core Web Vitals and additional web vitals,
                providing comprehensive insights into your site's performance.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                According to Forbes'&nbsp;
                <Link
                    textDecoration="underline"
                    to={"https://www.forbes.com/advisor/business/software/website-statistics/"}
                    target="new"
                >
                    Top Website Statistics For 2024
                </Link>
                , data from Visual Capitalist reveals a stark reality:{" "}
                <strong>"Websites that load slowly cost retailers $2.6 billion US dollars in sales each year."</strong>{" "}
                This demonstrates the direct connection between site performance and business outcomes.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Core Web Vitals
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Developed by Google, the Core Web Vitals measure crucial aspects of your users' experiences. It's worth
                noting that these metrics primarily represent users of Google-based browsers rather than the entire web
                ecosystem.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                These metrics are gathered through real user monitoring (RUM) via the&nbsp;
                <Link textDecoration="underline" to={"https://developer.chrome.com/docs/crux/"} target="new">
                    Chrome User Experience (CrUX) Report
                </Link>
                . Many platforms, including Shopify, leverage this data to capture authentic user interactions across
                various Google-based browsers, providing valuable insights into real-world site performance.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The CrUX report collects data from a small subset of Google browser users who have qualified for the
                program, meaning Google can record and publicize their data.{" "}
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Both the CrUX data and your resulting Core Web Vitals reflect a sample of your users and should be
                    considered within that sample context.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The Core Web Vitals include the following metrics, which can generally be expected to improve because
                of&nbsp;
                {BRAND_NAME}'s {MAIN_PRODUCT} speed improvements and optimizations.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Largest Contentful Paint (LCP)</strong> indicates when the largest element on a given page
                    (usually a photo or video in the eCommerce world) becomes visible to your customers. LCP measures
                    the time it takes the element to render and begins when a user requests the page via its URL.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Interaction To Next Paint (INP)</strong> determines a page's level of responsiveness to
                    human interactions based on how long it takes to react to actions like a click or scroll.
                    Google&nbsp;
                    <Link
                        textDecoration="underline"
                        to={"https://support.google.com/webmasters/answer/9205520?hl=en"}
                        target="new"
                    >
                        says
                    </Link>{" "}
                    the "final INP value is the longest interaction observed, ignoring outliers."
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Cumulative Layout Shift (CLS)</strong> refers to how much a page's layout or content moves
                    during a customer's visit. A score of 0 means no shifting is present, so the closer to 0 your score
                    is, the better your user experience will be.{" "}
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                According to{" "}
                <Link textDecoration="underline" to="https://web.dev/articles/vitals-spa-faq" target="new">
                    Google's documentation
                </Link>
                , faster pages help boost all of your Core Web Vitals: "Since Core Web Vitals assessments consider the
                75th percentile of page visits, having more, well-performing page visits in the dataset will increase
                the likelihood that the visit at the 75th percentile of the distribution will be within the recommended
                thresholds. … When aggregating the scores of all pages in an origin, individual fast pages can improve
                the 75th percentile for the origin as a whole."
            </Text>

            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Read on to see multiple case studies that demonstrate the clear eCommerce benefits of enhancing each of
                these scores.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Vodafone, an international telecommunications company,{" "}
                <Link textDecoration="underline" to={"https://web.dev/case-studies/vodafone"} target="new">
                    discovered
                </Link>{" "}
                a <strong>"31% improvement in LCP led to 8% more sales</strong>, a 15% improvement in their lead to
                visit rate, and an 11% improvement in their cart to visit rate."
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Ticketing company redBus reduced latency across their user experience by focusing on{" "}
                <strong>INP improvements, which led to a 7% increase in sales</strong>, as reported in a{" "}
                <Link textDecoration="underline" to={"https://web.dev/case-studies/redbus-inp"} target="new">
                    Core Web Vitals case study
                </Link>
                .{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Carpe, an antiperspirant-focused Shopify site,{" "}
                <Link
                    textDecoration="underline"
                    to={
                        "https://performance.shopify.com/blogs/blog/how-carpe-achieved-record-breaking-sales-by-focusing-on-performance-optimization"
                    }
                    target="new"
                >
                    experienced
                </Link>{" "}
                a 52% improvement in LCP and a 41% improvement in CLS thanks to performance enhancements, which led to
                a&nbsp;
                <strong>
                    5% increase in their online store conversion rate, a 10% boost in traffic, and a total revenue
                    increase of 15%.
                </strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Renault, an automobile manufacturer,{" "}
                <Link textDecoration="underline" to={"https://web.dev/case-studies/renault"} target="new">
                    assessed data
                </Link>{" "}
                from "10 million visits on its landing pages" and reported that a{" "}
                <strong>
                    "1-second LCP improvement can lead to a 14 percentage points (ppt) decrease in bounce rate and 13%
                    increase in conversions."
                </strong>
            </Text>
            <HStack mb={"spacer-6"} backgroundColor={"blue.100"} p={"spacer-6"} alignItems={"center"}>
                <ChatIcon boxSize={6} />
                <Stack pl={"spacer-4"}>
                    <Text textStyle={"text-header-S"} mb={"spacer-2"}>
                        "When we started using {BRAND_NAME}, our site speed increased overnight. After that, our{" "}
                        <Link
                            textDecoration="underline"
                            to={
                                "https://www.nostra.ai/success-stories/how-rifruf-increased-its-conversion-rate-by-eight-percent"
                            }
                            target="new"
                        >
                            conversions immediately lifted
                        </Link>
                        , and our bounce rates improved as well."
                    </Text>
                    <Text textStyle={"text-body-regular"}>—Peter Liu, Co-Founder and CEO at RIFRUF</Text>
                </Stack>
            </HStack>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Additional Web Vitals
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                There are additional web vitals to consider that are supplemental to the main Core Web Vitals, such as
                Time-To-First-Byte, First Contentful Paint, and Total Blocking Time.{" "}
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Time-To-First-Byte (TTFB)</strong> measures web server responsiveness by tracking the time
                    it takes a request to respond and begin downloading your content. This timing includes redirects,
                    the DNS lookup, the request, and more.{" "}
                    <strong>
                        Nostra AI's Edge Delivery Engine™ is especially known for its incredible reduction of TTFB,
                        often resulting in a 5-10 time reduction
                    </strong>{" "}
                    and benefiting every other part of your user experience, including the Core Web Vitals.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>First Contentful Paint (FCP)</strong> measures when the first bit of content becomes visible
                    to a user and occurs before your LCP. FCP is important to consider as it marks the first moment
                    visitors perceive the page response.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Total Blocking Time (TBT)</strong> measures how long after your FCP your main thread was
                    blocked—meaning the browser could not interrupt a long task—long enough to interfere with the user
                    experience. This is defined as any task that is longer than 50 milliseconds that will make the user
                    perceive the page as slow.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Terra, a news and entertainment platform,{" "}
                <Link
                    textDecoration="underline"
                    to={"https://web.dev/case-studies/terra-prefetching-case-study?hl=en"}
                    target="new"
                >
                    relied
                </Link>{" "}
                on prefetching and TTFB reductions to receive an{" "}
                <strong>
                    11% increase in their mobile ad click-through rate (CTR), a 30% increase in their desktop ad CTR,
                    and a 50% improvement in LCP.
                </strong>
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} customers like RIFRUF, MìLà, and Healthycell have seen their TTFB decrease by around
                70-80%. The benefits are widespread, as the faster your content begins to download, the better your FCP
                and LCP will automatically be.
            </Text>
            <HStack mb={"spacer-6"} backgroundColor={"blue.100"} p={"spacer-6"} alignItems={"center"}>
                <ChatIcon boxSize={6} />
                <Stack pl={"spacer-4"}>
                    <Text textStyle={"text-header-S"} mb={"spacer-2"}>
                        "As a brand scales and adds more code to its Shopify store theme, it bogs the site down. In
                        response, we used {BRAND_NAME}'s {MAIN_PRODUCT} as a powerful tool to{" "}
                        <Link
                            textDecoration="underline"
                            to={
                                "https://www.nostra.ai/success-stories/how-mila-increased-revenue-per-user-by-465-with-nostra"
                            }
                            target="new"
                        >
                            ensure our load times stay low
                        </Link>{" "}
                        and our site speed stays competitive."
                    </Text>
                    <Text textStyle={"text-body-regular"}>—Brian Lok, Digital Storefront Lead at MìLà</Text>
                </Stack>
            </HStack>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Meanwhile, {BRAND_NAME}'s additional smart optimizations help reduce your JavaScript execution time and
                the impact of third-party code.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Where To Find Your Web Vitals
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can find these metrics in several places, depending on the kind of data you want to assess.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As defined by{" "}
                <Link
                    textDecoration="underline"
                    to={"https://web.dev/articles/lab-and-field-data-differences"}
                    target="new"
                >
                    Google Chrome's documentation
                </Link>
                , <strong>lab data</strong> is gathered in a consistent environment, producing results that help
                developers find, debug, and fix performance concerns.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                It is best used to test changes, debug issues, and determine good practices. Since lab data is gathered
                from a single version of a page, results and recommendations may or may not match the field data.
                Lighthouse, for example, measures lab data.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>Field data,</strong> or real user monitoring (RUM), is taken from actual users. This means
                testing occurs across many devices, networks, and locations. Field data is a good reflection of what at
                least a sample of your users are experiencing, and Google generally recommends prioritizing field data
                over lab data since field data is more telling of the real world.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Field data is most often taken from the CrUX Report, which is updated once a month. That same data is
                used by PageSpeed Insights (in addition to lab data from Lighthouse).
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                As mentioned above, these Google-defined web vitals are not available across all browsers. Generally,
                Google and Apple-based browsers will provide the page load time, TTFB, and the Core Web Vitals.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can use performance measurement tools like WebPageTest and GTmetrix to do additional testing.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
