import { PageContainer } from "../../PageContainer";
import {
    Text,
    Box,
    UnorderedList,
    ListItem,
    Alert,
    AlertIcon,
    TableContainer,
    Table,
    Thead,
    Th,
    Tr,
    Tbody,
    Td,
    Code,
} from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { ROUTES } from "../../../Routes";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { BRAND_NAME, MAIN_PRODUCT, SECONDARY_PRODUCT } from "../../../config/BRAND_NAME";

export const PageSpeedInsightsDocumentation = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Third Party Tools"}
                menuItem1Link={ROUTES.DOCUMENTATION_THIRD_PARTY_DEFAULTS}
                menuItem2={`PageSpeed Insights `}
            />
            <Header pb={"spacer-8"}>PageSpeed Insights</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                PageSpeed Insights is one of Google's most popular speed assessment tools. It offers a combination of
                Chrome User Experience Report (CrUX) field data and Lighthouse lab data to help identify opportunities
                for improvement.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The testing tool can be used to assess a specific page or your entire website (origin) across desktop or
                mobile devices.
            </Text>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Already know the basics? Skip to the{" "}
                    <a href="#field-data" style={{ textDecoration: "underline" }}>
                        PageSpeed Insights Field Data
                    </a>
                    ,{" "}
                    <a href="#lab-data" style={{ textDecoration: "underline" }}>
                        PageSpeed Insights Lab Data
                    </a>
                    , or&nbsp;
                    <a href="#nostra" style={{ textDecoration: "underline" }}>
                        PageSpeed Insights + {BRAND_NAME} section
                    </a>
                    .{" "}
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Overview
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Using PageSpeed Insights is simple. On the{" "}
                <Link textDecoration="underline" to="https://pagespeed.web.dev/" target="new">
                    PageSpeed Insights page
                </Link>
                , enter a URL you would like to assess and select "Analyze." It will take a moment to load, and then you
                will be ready to explore the data.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-24/6631b6b1-37bf-4b74-9deb-9413c0e2b1b0/screenshot.jpeg?tl_px=14,0&br_px=1391,525&force_format=png&width=1120.0"
                alt="PageSpeed Insights page"
                loading="eager"
            />
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Benefits Of PageSpeed Insights:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>You can assess field and lab data together in one report.</ListItem>
                <ListItem pb={"spacer-4"}>
                    You can immediately test changes to your source code and see impacts in the Lighthouse data.
                    However,{" "}
                    <strong>changes will not be reflected in the field data until the CrUX dataset is updated. </strong>
                    In the case of PageSpeed Insights, the CrUX section reflects aggregated data from the previous
                    28-day collection period.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    Lab data can highlight areas for improvement even when the field data is positive.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Limitations Of PageSpeed Insights:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    The results may contradict each other since they come from different datasets. Google{" "}
                    <Link
                        textDecoration="underline"
                        to="https://web.dev/articles/lab-and-field-data-differences"
                        target="new"
                    >
                        recommends
                    </Link>
                    &nbsp;prioritizing the field data when choosing between the options since field data reflects
                    real-world user experiences.
                </ListItem>
                <ListItem pb={"spacer-4"}>Field data is only available for public pages in the CrUX dataset.</ListItem>
                <ListItem pb={"spacer-6"}>
                    The data only includes Chrome users' experiences, as the field data is derived from CrUX and
                    Lighthouse is based in Chrome. This means all the data collected is a sample of your overall
                    traffic.
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                It's important to have a good understanding of the differences between lab and field data before drawing
                conclusions from the metrics you see in your report, considering the two data sources may not match.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"}>
                Lab Versus Field Data
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                One of the largest factors to keep in mind when analyzing speed scores is the type of data you are
                seeing.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Lab data</strong> is primarily meant for developers. Lab tests are run with a high level of
                    control from end to end. They reflect a "single device connected to a single network run from a{" "}
                    <Link
                        textDecoration="underline"
                        to="https://web.dev/articles/lab-and-field-data-differences"
                        target="new"
                    >
                        single geographic location
                    </Link>
                    ." As such, lab data is best used to test changes, debug issues, and determine good practices.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Field data,</strong> or real user monitoring (RUM), is user data that reflects the
                    real-world experiences of your customer base. This means data collection occurs across many devices,
                    networks, and locations. Field data is the best way to examine your users' interactions and
                    engagement, but it may not always identify why a problem exists or how to fix it.
                </ListItem>
            </UnorderedList>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    Since lab data is gathered from a single manufactured page experience, results and recommendations
                    may or may not match the field data.{" "}
                    <strong>
                        Google{" "}
                        <Link
                            textDecoration="underline"
                            to="https://web.dev/articles/lab-and-field-data-differences#what_to_do_when_the_results_are_different"
                            target="new"
                        >
                            recommends
                        </Link>{" "}
                        that speed score evaluators prioritize field data—specifically, the Core Web Vitals—over lab
                        data
                    </strong>{" "}
                    as real user experiences are key to a successful page or website.
                </Text>
            </Alert>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Both lab and field data can positively contribute to your site's performance if you understand their
                differences and how to best apply them to your goals. Keep these distinctions in mind as you navigate
                the metrics available to you via PageSpeed Insights.
            </Text>
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="field-data">
                PageSpeed Insights Field Data
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The field data section can be identified by the header, "Discover what your real users are
                experiencing," and is located at the top of the report.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-28/88b4268b-60e5-4bf7-b5eb-068823153be5/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1002,607&force_format=jpeg&q=100&width=1088"
                alt="PageSpeed Insights field data metrics"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Assuming the page has sufficient data available in the CrUX report, you will see information for the
                following metrics:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://www.nostra.ai/blogs-collection/largest-contentful-paint-lcp"
                            target="new"
                        >
                            Largest Contentful Paint (LCP)
                        </Link>
                    </strong>{" "}
                    indicates when the largest element on a given page (usually a photo or video in the eCommerce world)
                    becomes visible to your customers. LCP measures the time it takes that element to render and begins
                    when the page first starts to load.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link textDecoration="underline" to="https://web.dev/articles/inp" target="new">
                            Interaction To Next Paint (INP)
                        </Link>
                    </strong>{" "}
                    determines a page's level of responsiveness to human interactions throughout its lifespan based on
                    the latency (or delay) in milliseconds for the browser to react to actions like a click or tap.
                    Google{" "}
                    <Link
                        textDecoration="underline"
                        to="https://support.google.com/webmasters/answer/9205520?hl=en"
                        target="new"
                    >
                        says
                    </Link>{" "}
                    the "final INP value is the longest interaction observed, ignoring outliers." A low INP ensures that
                    the page will be reliably responsive.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://www.nostra.ai/blogs-collection/cumulative-layout-shift-cls-how-to-measure-optimize-cls"
                            target="new"
                        >
                            Cumulative Layout Shift (CLS)
                        </Link>
                    </strong>{" "}
                    refers to how much a page's layout or content unexpectedly moves during a customer's visit as
                    resources load. A score of 0 means no shifting is present, so the closer to 0 your score is, the
                    better your user experience will be.
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>
                        <Link
                            textDecoration="underline"
                            to="https://developer.chrome.com/docs/lighthouse/performance/first-contentful-paint"
                            target="new"
                        >
                            First Contentful Paint (FCP)
                        </Link>
                    </strong>{" "}
                    tracks the time it takes for the first bit of content to become visible to a user and occurs before
                    your Largest Contentful Paint (LCP). This time is important to consider as it marks the first moment
                    your visitors perceive the page response.
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>
                        <Link textDecoration="underline" to="https://web.dev/articles/ttfb" target="new">
                            Time-To-First-Byte (TTFB)
                        </Link>
                    </strong>
                    &nbsp;follows connection setup time and web server responsiveness by tracking the time it takes a
                    request to respond to and begin downloading your content. This timing includes redirects, the DNS
                    lookup, the request, and more. The CrUX documentation{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/crux/methodology/metrics"
                        target="new"
                    >
                        notes
                    </Link>{" "}
                    that TTFB data is only collected on full page loads, meaning “the sample size of TTFB can be smaller
                    than other metrics.”
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Data Collection
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The field information comes from the page's or website's CrUX report and reflects aggregated data from
                the previous 28-day collection period. Unlike the CrUX Dashboard, the PageSpeed Insights field data is
                updated daily based off the latest aggregation. According to the{" "}
                <Link
                    textDecoration="underline"
                    to="https://developer.chrome.com/docs/crux/guides/pagespeed-insights"
                    target="new"
                >
                    documentation
                </Link>
                , this means you won't "necessarily be able to see how a site's performance is trending" over time.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You will not have access to every metric on the CrUX Dashboard via PageSpeed Insights, and you cannot
                directly compare a single page report to your CrUX report, as the CrUX Dashboard only reports on a
                site's origin. For more information on all the metrics available in the dashboard, visit our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_THIRD_PARTY_CRUX} target="new">
                    Chrome User Experience Report documentation
                </Link>
                .
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If there is not enough CrUX data for a specific page, PageSpeed Insights will either rely on real user
                data from the entire website (AKA origin) or be unable to display any data. If one of the Core Web
                Vitals does not have enough data to be included, the Core Web Vitals Assessment will be based on the
                remaining two instead.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-28/734050f8-fe37-4c9b-a067-4bd6106254b4/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2000,425&force_format=jpeg&q=100&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=1040,154"
                alt="PageSpeed Insights settings"
            />
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Interpreting The Results
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Each metric shows a distribution between "Good," "Needs Improvement," and "Poor," and can be expanded to
                show the percentage of users who experienced each level.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                PageSpeed Insights and the original CrUX dataset highlight the 75th percentile of each metric with a
                tick mark. Looking at a Largest Contentful Paint (LCP) in the image below, this particular page has a
                P75 value of 2.5 seconds, which means 75% of your users experienced an LCP of less than 2.5 seconds,
                while the remaining 25% saw an LCP of more than 2.5 seconds.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2025-02-28/75dfa266-1dbc-470f-86e0-0552cd51ff5e/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=952,761&force_format=jpeg&q=100&width=1120.0"
                alt="PageSpeed Insights metrics expanded"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="lab-data">
                PageSpeed Insights Lab Data
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                PageSpeed Insights contains lab data as measured by Lighthouse, which can be found under the header,
                "Diagnose performance issues," after the CrUX data.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                All the same audits from Lighthouse are available in PageSpeed Insights. According to Chrome's&nbsp;
                <Link textDecoration="underline" to="https://web.dev/articles/vitals-tools" target="new">
                    documentation
                </Link>
                , the only difference is that "since Lighthouse is run from the server, it can form a more consistent
                baseline than running Lighthouse from DevTools."
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-24/6dbee233-21da-410e-9bd5-7e158500f7a6/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=1005,811&force_format=png&width=1120.0"
                alt="PageSpeed Insights lab data"
            />
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Data Collection
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Lighthouse is an open-source resource from Google Chrome that allows developers to audit the
                performance, accessibility, best practices, and SEO of any page, public or private (AKA authenticated).{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                All Lighthouse tests measure lab data and generally analyze a first-load experience before any content
                has been cached. However,{" "}
                <strong>
                    {BRAND_NAME} has configured our system to deliver the {MAIN_PRODUCT} version of your site to
                    Lighthouse by default and has provided parameters to override our default setting.
                </strong>{" "}
            </Text>
            <TableContainer pb={"spacer-6"}>
                <Table variant="striped" colorScheme="gray" size={"md"} whiteSpace={"pre-wrap"}>
                    <Thead>
                        <Tr>
                            <Th>Parameter</Th>
                            <Th>Description</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>
                                <Code bg="white">?skipSeo</Code>
                            </Td>
                            <Td>
                                Ensures you receive the human version of your site with {BRAND_NAME}'s {MAIN_PRODUCT}{" "}
                                enabled—the default behavior
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Code>?forceSeo</Code>
                            </Td>
                            <Td>
                                Ensures you receive the bot version of your site with {BRAND_NAME}'s {SECONDARY_PRODUCT}{" "}
                                enabled, if applicable to your store
                            </Td>
                        </Tr>
                        <Tr>
                            <Td>
                                <Code bg="white">?skipCache</Code>
                            </Td>
                            <Td>Ensures you receive your original site</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Visit our{" "}
                <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_THIRD_PARTY_DEFAULTS} target="new">
                    Understanding Testing Defaults With {BRAND_NAME} documentation
                </Link>{" "}
                for more information.
            </Text>
            <Text textStyle={"text-body-bold"} pb={"spacer-6"}>
                Interpreting The Results
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                A site's "Performance" audit is of particular interest in the eCommerce world, as it measures site speed
                based on factors such as rendering, JavaScript execution, stylesheet usage, and redundancy.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Your overall performance score is a weighted average of several key metrics. Each metric is given a
                score of 0-100, based on where the tested page falls in a scoring distribution created from an HTTP
                archive of website performance data. That means your Largest Contentful Paint, Speed Index, etc. are
                calculated in a holistic manner, rather than as individual drops in a bucket.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                If you hover your mouse over the score, you will see a breakdown of the metrics influencing your score
                the most.{" "}
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    <strong>
                        Your performance score will fluctuate, even if you run two reports back to back without making
                        any changes.
                    </strong>{" "}
                    This apparent inconsistency takes place for several reasons, largely due to differences in
                    technology across networks and websites. For a more comprehensive list, see the "Variability"
                    section of our{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_THIRD_PARTY_LIGHTHOUSE} target="new">
                        Lighthouse documentation
                    </Link>
                    .
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-29/9c8f2762-e8e5-4871-854d-808fb63e9013/screenshot.jpeg?tl_px=0,0&br_px=474,379&force_format=png&width=860"
                alt="Breakdown of performance score"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Below your overall score, you will find a list of "Diagnostics" with suggestions on how to decrease load
                times, reduce media and payload sizes, and more. This list is the result of smaller audits that the page
                in question failed to pass.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                You can filter the list by selecting First Contentful Paint (FCP), Largest Contentful Paint (LCP), Total
                Blocking Time (TBT), or Cumulative Layout Shift (CLS) to view only the audits that contribute to those
                metrics or display all of the recommendations together.{" "}
            </Text>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text>
                    There is only an "indirect relationship" between the diagnostics listed and your page score,
                    according to the{" "}
                    <Link
                        textDecoration="underline"
                        to="https://developer.chrome.com/docs/lighthouse/performance/performance-scoring/?utm_source=lighthouse&utm_medium=lr"
                        target="new"
                    >
                        documentation
                    </Link>
                    . This means while taking advantage of your diagnostic recommendations <i>could</i> improve your
                    page score because they improve a metric, the only guaranteed influences are the metrics themselves.
                </Text>
            </Alert>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-07-25/f3fc836d-da6d-43dd-aedf-726c137e5d16/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=739,347&force_format=png&width=860"
                alt="Lighthouse diagnostics section"
            />
            <SubHeader textStyle={"text-header-M"} pb={"spacer-6"} id="nostra">
                PageSpeed Insights + {BRAND_NAME}
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                PageSpeed Insights provides an easy way to view field and lab data in one place. As with any metrics
                related to {BRAND_NAME}, keep in mind our products are optimization tools. If your website updates its
                third-party applications, product images and slideshows, etc., those changes could impact your scores
                unrelated to your performance gains with {BRAND_NAME}.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Google{" "}
                <Link
                    textDecoration="underline"
                    to="https://web.dev/articles/lab-and-field-data-differences#what_to_do_when_the_results_are_different"
                    target="new"
                >
                    recommends
                </Link>{" "}
                that speed score evaluators prioritize field data—specifically, the Core Web Vitals—over lab data
                metrics and scores, as real user experiences are key to a successful page or website.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Both lab and field data can positively contribute to your site's performance if you understand their
                differences and how to best apply them to your goals. {BRAND_NAME} is here to help deepen your
                understanding of these scores and how you can benefit from them.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                <strong>
                    {BRAND_NAME}'s Shopify-based customers have seen overall performance improvements of 20-30%, while
                    Salesforce Commerce Cloud-based customers have seen overall performance improvements of over 40%.
                </strong>{" "}
                You can start to see these benefits shortly after onboarding with your lab data via Lighthouse, but note
                that it will take time to see gains in your field data because of how the CrUX data is updated.
            </Text>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
