import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon } from "@chakra-ui/react";
import { Header, SubHeader, Link } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME, MAIN_PRODUCT } from "../../../config/BRAND_NAME";

export const CacheHitRatioResource = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Clear Cache"}
                menuItem1Link={ROUTES.DOCUMENTATION_WHEN_AND_WHY_TO_PURGE_CACHE}
                menuItem2={"Cache Hit Ratio"}
            />
            <Header pb={"spacer-8"}>Cache Hit Ratio</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                {BRAND_NAME} is committed to consistently delivering an exceptional experience to users when they visit
                your site, which is supported by maintaining a high cache hit ratio.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Most customers attain this desired ratio quickly after onboarding. However, some stores may require
                additional customization due to traffic patterns or the frequency of content updates.
            </Text>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                What Is Your Cache Hit Ratio?
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Your store's cache hit ratio measures the percentage of your content that is served by our{" "}
                {MAIN_PRODUCT}, meaning the percentage of your overall user-facing content that is enhanced with{" "}
                {BRAND_NAME}. The ratio may seem like a straightforward number, but there are several factors to
                consider in its calculation.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Is your site on a split (A/B) test with {BRAND_NAME}?</strong> Any split testing will affect
                    your cache hit ratio. For example, a store on a 50/50 split with a reported cache hit ratio of 41%
                    should be doubled to account for full exposure to our {MAIN_PRODUCT}. Its cache hit ratio would
                    instead be projected out to 82%.{" "}
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>How much of your content is captured by our advanced caching system?</strong> Our{" "}
                    {MAIN_PRODUCT} does not currently support content such as user account pages, cart pages, and
                    checkout pages.{" "}
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>How much of your content is exclusive to logged-in users?</strong> {BRAND_NAME} does not
                    cache logged-in content by default. Contact support if this is an important use case for your store.{" "}
                </ListItem>
            </UnorderedList>
            <Alert status="warning" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    Because of these factors, your cache hit ratio will never reach 100% and will be assessed according
                    to your store's specific needs.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Managing Cache Hit Ratio
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our team will configure your cache settings during onboarding to support your store's unique setup.
                Especially at the beginning of your partnership with {BRAND_NAME}, that may mean adjusting your store's
                configuration to increase the number of users receiving your optimized content.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Examples of stores that could require additional customization include:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    A large, international eCommerce store with thousands of product pages
                </ListItem>
                <ListItem pb={"spacer-4"}>A small store with a low number of daily sessions</ListItem>
                <ListItem pb={"spacer-6"}>
                    A store that makes frequent changes to specific pages, such as a home page or blog page
                </ListItem>
            </UnorderedList>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Additional configuration may be required because of how {BRAND_NAME} updates cached content. When a
                store clears its cache, {BRAND_NAME}'s {MAIN_PRODUCT} must reprocess and optimize all the pages from
                your store to ensure all the cached content is current. The process is initiated when a user visits a
                page for the first time, telling the {MAIN_PRODUCT} to cache the refreshed content on the edge and
                deliver it to your incoming customers.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                When a page isn't visited often, either because of a high number of products or because there is not
                much traffic, those pages may take some time to enter the cache, decreasing the overall cache hit ratio.
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Rather than clearing your entire site's cache every time you make a small change, {BRAND_NAME} has
                several features available to optimize your experience.
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>
                    <strong>Clear the cache of only the page you change whenever possible.</strong> Our site speed
                    experts recommend this single-page approach to minimize the time your website pages are not stored
                    in the cache for optimal delivery. For more information, visit the "How To Clear A Single Page Vs.
                    Your Entire Site" section of our{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CLEAR_CACHE}>
                        How To Clear Your Cache documentation
                    </Link>
                    .
                </ListItem>
                <ListItem pb={"spacer-4"}>
                    <strong>Customize your cache duration.</strong> {BRAND_NAME} allows store owners to configure
                    specific cache durations for different pages or sections of a website based on factors such as the
                    frequency of updates or the importance of the content. For a detailed explanation of how and why to
                    adjust your cache settings, visit the&nbsp;
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_CACHE_SETTINGS}>
                        Custom Cache Settings documentation
                    </Link>
                    .
                </ListItem>
                <ListItem pb={"spacer-6"}>
                    <strong>Contact support.</strong> {BRAND_NAME} configures every store's cache hit ratio during
                    onboarding and beyond as needed, but you know your store best. If you have questions or concerns
                    about adding customization in the portal, help is available.
                </ListItem>
            </UnorderedList>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
