import { PageContainer } from "../../PageContainer";
import { Text, Box, UnorderedList, ListItem, Alert, AlertIcon, Code } from "@chakra-ui/react";
import { Header, SubHeader, Link, DocumentationImage } from "../../../components";
import { DocsBreadcrumbComponent } from "../DocsBreadcrumbComponent";
import { ROUTES } from "../../../Routes";
import { BRAND_NAME } from "../../../config/BRAND_NAME";

export const InstalledAppsResource = () => {
    return (
        <PageContainer>
            <DocsBreadcrumbComponent
                menuItem1={"Shopify Apps"}
                menuItem1Link={ROUTES.DOCUMENTATION_INSTALLED_APPS}
                menuItem2={"Installed Apps"}
            />
            <Header pb={"spacer-8"}>Installed Apps</Header>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                Our team needs to know a store's configuration, so the <Code>app-portal</Code> tracks Shopify stores'
                installed application data for stores with valid <Code>Admin Tokens</Code> and <Code>read_apps</Code>{" "}
                scope.{" "}
            </Text>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                All the applications are sorted alphabetically unless specifically sorted by the number of installs on
                the Installed Apps page.
            </Text>
            <Alert status="info" mb={"spacer-6"}>
                <AlertIcon />
                <Text textStyle={"text-body-regular"} py={"spacer-2"}>
                    The following application data is only accessible to CloudOps users.
                </Text>
            </Alert>
            <SubHeader textStyle={"text-header-S"} pb={"spacer-6"}>
                Installed Apps Page
            </SubHeader>
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The link to this page can be found in the portal's main navigation menu. It contains a list of all the
                apps installed by any client that meets the data collection criteria.
            </Text>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-29/5c031081-c5d6-4407-bad1-51b95e013e0f/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2293,1182&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=34,406"
                alt="Installed apps link"
                loading="eager"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                The features of this page include:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Filtering by application name</ListItem>
                <ListItem pb={"spacer-4"}>Filtering by "Most Installed"</ListItem>
                <ListItem pb={"spacer-6"}>
                    Accessing{" "}
                    <Link textDecoration="underline" to={ROUTES.DOCUMENTATION_SINGLE_APP}>
                        more information about a single application
                    </Link>{" "}
                    by clicking on the app title
                </ListItem>
            </UnorderedList>
            <DocumentationImage
                src="https://ajeuwbhvhr.cloudimg.io/colony-recorder.s3.amazonaws.com/files/2024-03-29/aac71c20-20c1-461b-83bd-3a732b3a5fb4/user_cropped_screenshot.jpeg?tl_px=0,0&br_px=2059,946&force_format=png&width=1120.0&wat=1&wat_opacity=1&wat_gravity=northwest&wat_url=https://colony-recorder.s3.amazonaws.com/images/watermarks/0EA5E9_standard.png&wat_pad=113,80"
                alt="Installed apps with app title clicked"
            />
            <Text textStyle={"text-body-regular"} pb={"spacer-6"}>
                CloudOps users can also see whether the app is supported by {BRAND_NAME}, a description of the
                application (if provided), and general installation statistics broken down into three categories:
            </Text>
            <UnorderedList textStyle={"text-body-regular"} pl={"spacer-6"}>
                <ListItem pb={"spacer-4"}>Active {BRAND_NAME} customers with the app installed</ListItem>
                <ListItem pb={"spacer-4"}>
                    Inactive {BRAND_NAME} customers that had the app installed while our systems could track their data
                </ListItem>
                <ListItem pb={"spacer-6"}>Total number of installations</ListItem>
            </UnorderedList>
            <Box padding={"spacer-11"} width="100" height={"45px"}></Box>
        </PageContainer>
    );
};
